'use strict';

/**
 * @description Dashboard Component
 * @returns {Object} Devuelve la instancia del componente
 */

import i18n from 'i18next';
import moment from 'moment-timezone';
import { store } from '../../app.init.js';
import { templateShareModal } from '../../shared/components/modal/modalShare.template.js';
import { componentSelects } from '../../shared/components/selects/selects.component.js';
import { componentToolbar } from '../../shared/components/toolbar/toolbar.component';
import { getUrlParams } from '../../utils/url.js';
import { Utils } from '../../utils/utils.js';
import { componentTab } from '../tab/tab.component.js';
import { balanceObjects } from './balance.content.js';
import { servicesBalance } from './balance.services.js';
import { templateBalance } from './balance.template.js';

let componentBalance = (function () {
  let context = {},
    itemSelected,
    handlers = {
      onSelectHandler: (e) => onSelectHandler(e),
      onSelectTwoHandler: (e) => onSelectTwoHandler(e),
      onStateHandler: () => onStateHandler(),
      onToogleHandler: () => onToogleHandler(),
    },
    date = moment().tz('Europe/Madrid'),
    renderSuccess = false,
    tabs,
    energyItems,
    selectedProgram,
    selectedAgg,
    selectedDate,
    obToolbar = [
      {
        id: 1,
        info: true,
        share: true,
        favorite: true,
        favId: 24,
        analysis: true,
        textModal: ``,
      },
    ],
    programs = balanceObjects.programs,
    categories = balanceObjects.categories,
    totalCategories = balanceObjects.totalCategories,
    rangeDate = [
      { alias: 'hour', name: 'daily_f', selected: '' },
      { alias: 'day', name: 'monthly', selected: '' },
      { alias: 'month', name: 'yearly', selected: '' },
    ],
    miArrayTabYear,
    miArrayTab,
    miArrayTabMonth,
    dataValue = [1, 2, 3, 4, 5, 6],
    sndTabValue = [7, 8, 9, 10, 11, 12],
    dayFirstTabValue = [0, 1, 2, 3, 4, 5],
    daySecondTabValue = [6, 7, 8, 9, 10, 11],
    daythirdTabValue = [12, 13, 14, 15, 16, 17],
    dayfourthTabValue = [18, 19, 20, 21, 22, 23],
    monthFirstTabValue = [0, 1, 2, 3, 4, 5, 6, 7],
    monthSecondTabValue = [8, 9, 10, 11, 12, 13, 14, 15],
    monthThirdTabValue = [16, 17, 18, 19, 20, 21, 22, 23],
    monthFourthTabValue = [24, 25, 26, 27, 28, 29, 30],
    arrayOfElements,
    finalArray,
    data,
    sortedList,
    wordFound,
    finalItems,
    numberOfDaysInMonth;

  const updateTabFromMobile = async (graphData) => {
    spinnerTrue('spinner1');
    let tableWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (tableWidth <= 540) {
      let renderTabFunc;
      if (selectedAgg === 'hour') {
        renderTabFunc = updateTabDay(
          tabs,
          dayFirstTabValue,
          daySecondTabValue,
          daythirdTabValue,
          dayfourthTabValue
        );
      } else if (selectedAgg === 'day') {
        renderTabFunc = updateTabMonth(
          tabs,
          monthFirstTabValue,
          monthSecondTabValue,
          monthThirdTabValue,
          getArrayOfFourTabDays([24, 25, 26, 27, 28, 29, 30], 1)
        );
      } else if (selectedAgg === 'month') {
        renderTabFunc = updateTabYear(tabs, dataValue, sndTabValue);
      }
      // Se renderiza la tabla con los valores por defecto
      componentTab.render(renderTabFunc, '#tabwithcontent');
      componentSelects.render(selectMobileSelector, '#mobile__selector');
      const selectedColumn = document
        .querySelector('.mobile__selector_id')
        .getAttribute('value');

      const selectedPrograms = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0];
      // Renderizamos la gráfica
      await renderGraph(
        graphData,
        selectedPrograms,
        categories,
        selectedAgg,
        selectedProgram
      );

      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(selectedColumn, selectedAgg);
      }

      document.querySelectorAll('.select-time-options .option')[0]?.click();
    }
    spinnerNone('spinner1');
  };

  // mobile__selector
  const selectMobileSelector = {
    id: 'mobile__selector_id',
    currentLabel: '0-1 H',
    currentValue: '0',
    label: '',
    options: [],
    columns: 1,
    onlySelect: true,
    changeTime: (value) => changeTimeFromSelector(value),
  };

  const changeTimeFromSelector = (value) => {
    const { id, newValue } = value;

    if (selectMobileSelector.id === id) {
      selectMobileSelector.options.map((el) => {
        if (el.value === newValue) {
          selectMobileSelector.currentLabel = el.label;
          selectMobileSelector.currentValue = el.value;
          renderColumnSelected(el.value, urlParams.get('agg'));
          componentSelects.render(selectMobileSelector, '#mobile__selector');
        }
      });

      return;
    }
  };
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let setInfoModal = (id, data, html, updateShareModal = 'all') => {
    if (updateShareModal !== 'share') {
      obToolbar[id].textModal = `<h2>${data.name}</h2>${data.description}`;
    }
    data.indicators?.sort((a, b) => a.id - b.id);
    obToolbar[id].widgetData = data;
    obToolbar[id].uri = '/' + obToolbar[id].favId + '?' + obToolbar[id]?.params;
    obToolbar[id].shareModal = html;
    componentToolbar.render(obToolbar[id], '.esios-toolbar' + (id + 1));
  };

  const updateMobileSelectorOpts = (aggURL) => {
    selectMobileSelector.options = getArrayOfHoursForBalance(aggURL);
    if (aggURL == 'hour') {
      selectMobileSelector.currentLabel = '0-1 H';
    } else if (aggURL == 'day') {
      selectMobileSelector.currentLabel = '1';
    } else if (aggURL == 'month') {
      selectMobileSelector.currentLabel = i18n.t('january');
    }
    componentSelects.render(selectMobileSelector, '#mobile__selector');
  };

  const setParameter = (key, value) => {
    urlParams.set(key, value);
    history.pushState({}, '', window.location.pathname + '?' + urlParams);
  };

  const onSelectHandler = async (e) => {
    itemSelected = e.target.innerText;
    spinnerTrue('spinner1');

    programs.map(async (item, i) => {
      if (itemSelected == item.name) {
        setParameter('program', item.name);
        selectedProgram = item.name;
        let spanSelectOne = document.getElementById('selectOne');
        spanSelectOne.innerHTML = itemSelected;
        spanSelectOne.setAttribute('value', itemSelected);
        await renderTabs();
        spinnerTrue('spinner1');
      }
    });

    if (
      itemSelected ==
        i18n.t('daily_f').charAt(0).toUpperCase() +
          i18n.t('daily_f').slice(1) ||
      itemSelected ==
        i18n.t('monthly').charAt(0).toUpperCase() +
          i18n.t('monthly').slice(1) ||
      itemSelected ==
        i18n.t('yearly').charAt(0).toUpperCase() + i18n.t('yearly').slice(1)
    ) {
      selectedAgg = e.target.getAttribute('value');
      setParameter('agg', e.target.getAttribute('value'));
      onToogleHandler();
      let spanSelectTwo = document.getElementById('selectTwo');
      spanSelectTwo.innerHTML = itemSelected.toUpperCase();
      spanSelectTwo.setAttribute('value', itemSelected.toUpperCase());
      await renderTabs();
      spinnerTrue('spinner1');
    }
    spinnerTrue('spinner1');
    await renderGraphAgain();
    spinnerNone('spinner1');
    updateMobileSelectorOpts(selectedAgg);
  };

  const onStateHandler = () => {
    let list = document.getElementById('list_programs');
    let listTwo = document.getElementById('list_selections');

    if (list.className === 'esios-select__selectableHidden') {
      list.classList.remove('esios-select__selectableHidden');
      list.classList.add('esios-select__selectable');
      listTwo.classList.add('esios-select__selectableHidden');
    } else {
      list.classList.remove('esios-select__selectable');
      list.classList.add('esios-select__selectableHidden');
    }
  };

  const onToogleHandler = () => {
    let listTwo = document.getElementById('list_selections');
    let list = document.getElementById('list_programs');

    if (listTwo.className === 'esios-select__selectableHidden') {
      listTwo.classList.remove('esios-select__selectableHidden');
      listTwo.classList.add('esios-select__selectable');
      list.classList.add('esios-select__selectableHidden');
    } else {
      listTwo.classList.remove('esios-select__selectable');
      listTwo.classList.add('esios-select__selectableHidden');
    }
  };

  const calculateTotalGeneration = (maxArrVal) => {
    const newArrr = {
      composited: true,
      disaggregated: false,
      id: 90000,
      name: `${i18n.t('total_generation')}`,
      short_name: `${i18n.t('total_generation')}`,
      values: [],
    };

    for (let quantityFormat = 0; quantityFormat < maxArrVal; quantityFormat++) {
      let totalGene = [];
      totalGene[quantityFormat] = 0;
      for (
        let indicatorCounter = 0;
        indicatorCounter < 13;
        indicatorCounter++
      ) {
        totalGene[quantityFormat] +=
          finalArray[indicatorCounter]?.values[quantityFormat] === undefined
            ? 0
            : finalArray[indicatorCounter]?.values[quantityFormat].value;
      }
      newArrr.values.push({
        value: totalGene[quantityFormat],
      });
    }

    finalArray.splice(13, 0, newArrr);

    finalArray = finalArray.map((el) => {
      if (balanceObjects.categories[19].indicators.includes(el.id)) {
        return {
          ...el,
          short_name: `${i18n.t('programs_adjustment')}`,
        };
      } else return el;
    });
  };

  const updateTabYear = (dataElement, dataValue, sndTabValue) => {
    let firstYear = ['january', 'february', 'march', 'april', 'may', 'june'];
    let secondYear = [
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];
    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];

    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;

    let arrForLoop = Array.from(Array(12).keys());
    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: arrForLoop.map(() => {
          return undefined;
        }),
      };
    });

    for (let i = 0; i < finalArray.length; i++) {
      for (let x = 0; x < finalArray[i].values.length; x++) {
        tempArr[i].values.splice(
          parseInt(
            moment(finalArray[i].values[x].datetime)
              .tz('Europe/Madrid')
              .format('MM')
          ) - 1,
          1,
          finalArray[i].values[x]
        );
      }
    }

    finalArray = tempArr;
    calculateTotalGeneration(12);

    miArrayTabYear = [
      {
        text: `${i18n.t('january')} - ${i18n.t('june')}`,
        content: `
        <div>
          <table>
          <tbody>
            <tr class="esios-balance__tabs-row table-title-row">
              <td class="esios-balance__tabs-row-title">
                <div class="esios-balance__tabs-title">
                      <p>${i18n.t('month')}</p>
                    </div>
                  </td>
                  ${firstYear
                    .map(
                      (year) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${i18n.t(year)}</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
                ${finalArray
                  .map((element, i) => {
                    return `
                  <tr  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row tab-border-bottom'"
                      : "class='esios-balance__tabs-row'"
                  }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                      element.id === 10196
                        ? i18n.t('programs_adjustment')
                        : element.id == 10186
                        ? i18n.t('interconnections_balance')
                        : element.short_name
                    }</td>
                    ${dataValue
                      .map(
                        (item, ind) =>
                          `<td  ${
                            element.id == 10186 ||
                            element.short_name == 'Demanda Peninsular' ||
                            element.short_name ==
                              `${i18n.t('total_generation')}`
                              ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                              : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                          }>${
                            finalArray[i].values[item - 1]?.value ==
                              undefined ||
                            finalArray[i].values[item - 1]?.value == null ||
                            finalArray[i].values[item - 1]?.value == 0
                              ? '-'
                              : Utils.toNumber(
                                  finalArray[i].values[item - 1].value,
                                  1,
                                  'comma'
                                )
                          }</td>`
                      )
                      .join('')}
                  </tr>`;
                  })
                  .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `${i18n.t('july')} - ${i18n.t('december')}`,
        content: `<div>
        <table>
        <tbody>
        <tr class="esios-balance__tabs-row table-title-row">
          <td class="esios-balance__tabs-row-title">
            <div class="esios-balance__tabs-title">
                <p>${i18n.t('month')}</p>
              </div>
            </td>
            ${secondYear
              .map(
                (year) => `
              <td>
              <div class="esios-balance__tabs-column-title">
                  <p>${i18n.t(year)}</p>
                </div>
              </td>
            `
              )
              .join('')}
          </tr>
          </thead>
          <tbody>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${sndTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item - 1]?.value == undefined ||
                      finalArray[i].values[item - 1]?.value == null ||
                      finalArray[i].values[item - 1]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item - 1].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];
    return miArrayTabYear;
  };

  const updateTabDay = (
    dataElement,
    dayFirstTabValue,
    daySecondTabValue,
    daythirdTabValue,
    dayfourthTabValue
  ) => {
    let { date } = getUrlParams();
    let rangeNum = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
    let firstHours =
      rangeNum === 25
        ? ['0-1', '1-2', '2-2B', '2B-3', '3-4', '4-5', '5-6']
        : ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6'];
    let secondHours = ['6-7', '7-8', '8-9', '9-10', '10-11', '11-12'];
    let thirdHours = ['12-13', '13-14', '14-15', '15-16', '16-17', '17-18'];
    let fourthHours = ['18-19', '19-20', '20-21', '21-22', '22-23', '23-24'];
    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];
    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;

    let hoursArray = Utils.getArrayOfHours();
    if (rangeNum === 25) {
      hoursArray.push({ label: '24', value: '24' });
      dayFirstTabValue = [0, 1, 2, 3, 4, 5, 6];
      daySecondTabValue = [7, 8, 9, 10, 11, 12];
      daythirdTabValue = [13, 14, 15, 16, 17, 18];
      dayfourthTabValue = [19, 20, 21, 22, 23, 24];
    }

    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: hoursArray.map(() => {
          return undefined;
        }),
      };
    });
    let doubleSameHour;
    for (let i = 0; i < finalArray.length; i++) {
      doubleSameHour = false;
      for (let x = 0; x < finalArray[i].values.length; x++) {
        let hour = parseInt(
          moment(finalArray[i].values[x].datetime)
            .tz('Europe/Madrid')
            .format('HH')
        );

        if (hour > 2) {
          doubleSameHour = true;
        }

        let nextHour = parseInt(
          moment(finalArray[i]?.values[x + 1]?.datetime)
            .tz('Europe/Madrid')
            .format('HH')
        );
        tempArr[i].values.splice(
          rangeNum == 25 && hour >= 2 && doubleSameHour ? hour + 1 : hour,
          1,
          finalArray[i].values[x]
        );

        if (hour == nextHour) {
          doubleSameHour = true;
        }
      }
    }
    finalArray = tempArr;
    calculateTotalGeneration(rangeNum === 23 ? rangeNum + 1 : rangeNum);

    if (rangeNum === 23) {
      firstHours = firstHours.filter((el) => el !== '2-3');
      dayFirstTabValue = dayFirstTabValue.filter((el) => el !== 2);
    }

    miArrayTab = [
      {
        text: `0 - 6 H`,
        content: `<div>
            <table>
            <tbody>
                <tr class="esios-balance__tabs-row table-title-row">
                  <td class="esios-balance__tabs-row-title table-title-row">
                    <div class="esios-balance__tabs-title">
                      <p>${i18n.t('hour')}</p>
                    </div>
                  </td>
                  ${firstHours
                    .map(
                      (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title ${hour}h">
                        <p>${hour} H</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
              ${finalArray
                .map((element, i) => {
                  return `
                <tr  ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class ='esios-balance__tabs-row tab-border-bottom'"
                    : "class='esios-balance__tabs-row'"
                }>
                  <td  ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? `class='esios-balance__tabs-row-title tab-row-bold'`
                      : `class='esios-balance__tabs-row-title'`
                  }>${
                    element.id === 10196
                      ? i18n.t('programs_adjustment')
                      : element.id == 10186
                      ? i18n.t('interconnections_balance')
                      : element.short_name
                  }</td>
                  ${dayFirstTabValue
                    .map(
                      (item, ind) =>
                        `<td  ${
                          element.id == 10186 ||
                          element.short_name == 'Demanda Peninsular' ||
                          element.short_name == `${i18n.t('total_generation')}`
                            ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                            : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                        }>${
                          finalArray[i].values[item]?.value == undefined ||
                          finalArray[i].values[item]?.value == null ||
                          finalArray[i].values[item]?.value == 0
                            ? '-'
                            : Utils.toNumber(
                                finalArray[i].values[item].value.toFixed(1),
                                1,
                                'comma'
                              )
                        }</td>`
                    )
                    .join('')}
                </tr>`;
                })
                .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `6 - 12 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${secondHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td  ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${daySecondTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth  column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value  column-with-data coltable-${ind}'`
                    }>
                  ${
                    finalArray[i].values[item]?.value == undefined ||
                    finalArray[i].values[item]?.value == null ||
                    finalArray[i].values[item]?.value == 0
                      ? '-'
                      : Utils.toNumber(
                          finalArray[i].values[item].value,
                          1,
                          'comma'
                        )
                  }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `12 - 18 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${thirdHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${daythirdTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth  column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value  column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value.toFixed(1),
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `18 - 24 H`,
        content: `<div>
        <table>
         <tbody>
          <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('hour')}</p>
              </div>
            </td>
            ${fourthHours
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour} H</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr  ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
              <td  ${
                element.short_name.trim().toLowerCase() ===
                  i18n.t('interconnections_balance').toLowerCase() ||
                element.short_name == 'Demanda Peninsular' ||
                element.short_name == `${i18n.t('total_generation')}`
                  ? "class='esios-balance__tabs-row-title tab-row-bold'"
                  : "class='esios-balance__tabs-row-title'"
              }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${dayfourthTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];

    return miArrayTab;
  };
  const getArrayOfFourTabDays = (arr, diff = 0) => {
    let numDaysTemp = moment(selectedDate, 'YYYY-MM-DD').daysInMonth();
    return arr.filter((el) => el <= numDaysTemp - diff && el);
  };
  const updateTabMonth = (
    dataElement,
    monthFirstTabValue,
    monthSecondTabValue,
    monthThirdTabValue,
    monthFourthTabValue
  ) => {
    let firstMonth = [1, 2, 3, 4, 5, 6, 7, 8];
    let secondMonth = [9, 10, 11, 12, 13, 14, 15, 16];
    let thirdMonth = [17, 18, 19, 20, 21, 22, 23, 24];
    let fourthMonth = getArrayOfFourTabDays([25, 26, 27, 28, 29, 30, 31]);

    arrayOfElements = categories.map((el) => el.name);
    arrayOfElements.splice(18, 0, totalCategories[1].alias);
    finalArray = [];
    let tempFinalArr = [];

    selectedProgram = selectedProgram || getUrlParams().program || 'P48';
    let tempIdOrderProgram = balanceObjects.programs.filter(
      (el) => el.name === selectedProgram
    );
    let idOrderArr = tempIdOrderProgram[0].indicators;
    let filterGraph = idOrderArr.filter((el, index) => index < 19);
    const orderedBarData = Utils.orderObjectsArrayByKey(
      dataElement,
      idOrderArr,
      'id'
    );
    finalArray = orderedBarData;
    tempFinalArr = orderedBarData;

    let numDaysTemp = moment(selectedDate, 'YYYY-MM-DD').daysInMonth();
    let arrForLoop = Array.from(Array(numDaysTemp).keys());
    let tempArr = tempFinalArr.map((el) => {
      return {
        ...el,
        values: arrForLoop.map(() => {
          return undefined;
        }),
      };
    });
    for (let i = 0; i < finalArray.length; i++) {
      for (let x = 0; x < finalArray[i].values.length; x++) {
        tempArr[i].values.splice(
          parseInt(
            moment(finalArray[i].values[x].datetime)
              .tz('Europe/Madrid')
              .format('DD')
          ) - 1,
          1,
          finalArray[i].values[x]
        );
      }
    }

    finalArray = tempArr;
    calculateTotalGeneration(numDaysTemp);
    miArrayTabMonth = [
      {
        text: `1-8 D`,
        content: `<div>
            <table>
              <tbody>
                <tr class="esios-balance__tabs-row table-title-row">
                  <td class="esios-balance__tabs-row-title">
                    <div class="esios-balance__tabs-title">
                      <p>${i18n.t('day')}</p>
                    </div>
                  </td>
                  ${firstMonth
                    .map(
                      (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
                  `
                    )
                    .join('')}
                </tr>
              ${finalArray
                .map((element, i) => {
                  return `
                <tr ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class ='esios-balance__tabs-row tab-border-bottom'"
                    : "class='esios-balance__tabs-row'"
                }>
                  <td ${
                    element.short_name.trim().toLowerCase() ===
                      i18n.t('interconnections_balance').toLowerCase() ||
                    element.short_name == 'Demanda Peninsular' ||
                    element.short_name == `${i18n.t('total_generation')}`
                      ? "class='esios-balance__tabs-row-title tab-row-bold'"
                      : "class='esios-balance__tabs-row-title'"
                  }>${
                    element.id === 10196
                      ? i18n.t('programs_adjustment')
                      : element.id == 10186
                      ? i18n.t('interconnections_balance')
                      : element.short_name
                  }</td>
                  ${monthFirstTabValue
                    .map(
                      (item, ind) =>
                        `<td  ${
                          element.id == 10186 ||
                          element.short_name == 'Demanda Peninsular' ||
                          element.short_name == `${i18n.t('total_generation')}`
                            ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                            : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                        }>${
                          finalArray[i].values[item]?.value == undefined ||
                          finalArray[i].values[item]?.value == null ||
                          finalArray[i].values[item]?.value == 0
                            ? '-'
                            : Utils.toNumber(
                                finalArray[i].values[item].value,
                                1,
                                'comma'
                              )
                        }</td>`
                    )
                    .join('')}
                </tr>`;
                })
                .join('')}
              </tbody>
            </table>
          </div>`,
      },
      {
        text: `9-16 D`,
        content: `<div>
        <table>
          <tbody>
          <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${secondMonth
              .map(
                (hour) => `
                    <td>
                      <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthSecondTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `17-24 D`,
        content: `<div>
        <table>
          <tbody>
           <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${thirdMonth
              .map(
                (hour) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthThirdTabValue
                .map(
                  (item, ind) =>
                    `<td ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
      {
        text: `25-${numDaysTemp} D`,
        content: `<div>
        <table>
          <tbody>
           <tr class="esios-balance__tabs-row table-title-row">
            <td class="esios-balance__tabs-row-title">
              <div class="esios-balance__tabs-title">
                <p>${i18n.t('day')}</p>
              </div>
            </td>
            ${fourthMonth
              .map(
                (hour) => `
                    <td>
                    <div class="esios-balance__tabs-column-title">
                        <p>${hour}</p>
                      </div>
                    </td>
            `
              )
              .join('')}
          </tr>
          ${finalArray
            .map((element, i) => {
              return `
            <tr ${
              element.short_name.trim().toLowerCase() ===
                i18n.t('interconnections_balance').toLowerCase() ||
              element.short_name == 'Demanda Peninsular' ||
              element.short_name == `${i18n.t('total_generation')}`
                ? "class ='esios-balance__tabs-row tab-border-bottom'"
                : "class='esios-balance__tabs-row'"
            }>
                <td ${
                  element.short_name.trim().toLowerCase() ===
                    i18n.t('interconnections_balance').toLowerCase() ||
                  element.short_name == 'Demanda Peninsular' ||
                  element.short_name == `${i18n.t('total_generation')}`
                    ? "class='esios-balance__tabs-row-title tab-row-bold'"
                    : "class='esios-balance__tabs-row-title'"
                }>${
                element.id === 10196
                  ? i18n.t('programs_adjustment')
                  : element.id == 10186
                  ? i18n.t('interconnections_balance')
                  : element.short_name
              }</td>
              ${monthFourthTabValue
                .map(
                  (item, ind) =>
                    `<td  ${
                      element.id == 10186 ||
                      element.short_name == 'Demanda Peninsular' ||
                      element.short_name == `${i18n.t('total_generation')}`
                        ? `class='esios-balance__tabs-row-title tab-row-weigth column-with-data coltable-${ind}'`
                        : `class='esios-balance__tabs-row-value column-with-data coltable-${ind}'`
                    }>${
                      finalArray[i].values[item]?.value == undefined ||
                      finalArray[i].values[item]?.value == null ||
                      finalArray[i].values[item]?.value == 0
                        ? '-'
                        : Utils.toNumber(
                            finalArray[i].values[item].value,
                            1,
                            'comma'
                          )
                    }</td>`
                )
                .join('')}
            </tr>`;
            })
            .join('')}
          </tbody>
        </table>
      </div>`,
      },
    ];
    return miArrayTabMonth;
  };

  // Se renderiza la tabla dependiendo de los filtros seleccionados y la fecha
  const renderTabs = async () => {
    spinnerTrue('spinner1');
    const state = store.getState();
    const inputCalendar = document.querySelector('#datepicker');
    let dateSelected = inputCalendar.value.trim();
    let formatDate = dateSelected.split('/');
    let [d, m, y] = formatDate;
    let contentPrograms = document
      .getElementById('selectOne')
      .getAttribute('value');

    let contentSelections = document
      .getElementById('selectTwo')
      .getAttribute('value');

    programs.map(async (item, i) => {
      if (itemSelected == item.name) {
        selectedProgram = item.id;
      }
    });

    const exportData = await servicesBalance.exportData(
      state.exampleReducer.currentLang,
      `${y.trim()}-${m.trim()}-${d.trim()}`
    );
    programs.map(async (item) => {
      if (contentPrograms == item.name) {
        if (
          contentSelections == i18n.t('daily_f').toUpperCase() ||
          contentSelections == 'hour'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'hour',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then(async (data) => {
              let modalText = await servicesBalance.modalData(
                state.exampleReducer.currentLang,
                `${y.trim()}-${m.trim()}-${d.trim()}`
              );
              let dataForExport = {
                description: modalText,
                indicators: tabs.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabDay(
              tabs,
              dayFirstTabValue,
              daySecondTabValue,
              daythirdTabValue,
              dayfourthTabValue
            ),
            '#tabwithcontent'
          );
        } else if (
          contentSelections == i18n.t('monthly').toUpperCase() ||
          contentSelections == 'day'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'day',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then((data) => {
              let dataForExport = {
                indicators: data.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabMonth(
              tabs,
              monthFirstTabValue,
              monthSecondTabValue,
              monthThirdTabValue,
              getArrayOfFourTabDays([24, 25, 26, 27, 28, 29, 30], 1)
            ),
            '#tabwithcontent'
          );
        } else if (
          contentSelections == i18n.t('yearly').toUpperCase() ||
          contentSelections == 'month'
        ) {
          tabs = await servicesBalance
            .dataTabs(
              state.exampleReducer.currentLang,
              item.indicators.map((item) => item),
              'month',
              `${y.trim()}-${m.trim()}-${d.trim()}`
            )
            .then((data) => {
              let dataForExport = {
                indicators: data.map((el) => {
                  return {
                    ...el,
                    print_values: el.values,
                  };
                }),
                ...exportData,
              };
              setInfoModal(
                0,
                dataForExport,
                templateShareModal(
                  'scheduled-generation-balance',
                  window.location.search,
                  false,
                  {
                    height: 1985,
                    width: '100%',
                  }
                ),
                'share'
              );
              return data;
            });
          componentTab.render(
            updateTabYear(tabs, dataValue, sndTabValue),
            '#tabwithcontent'
          );
        }
      }
    });

    let tableWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (tableWidth > 540) {
      spinnerNone('spinner1');
    }

    let tabTemp = document.querySelector('.mod-tabs ul li a');
    tabTemp.click();
  };

  let preRenderComponent = async () => {
    // Asi accedemos a la propiedad del store
    const state = store.getState();
    document.title = `${i18n.t('balance')} | ${i18n.t('page_subtitle')}`;
    context.name = state.exampleReducer.name;
  };

  let renderComponent = async () => {
    const state = store.getState();
    try {
      templateBalance(handlers, programs, categories, rangeDate, itemSelected);

      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.setAttribute('disabled', 'true');
      divCalendar.classList.add('disabled');

      document.getElementById('datepicker').addEventListener('click', () => {
        document
          .getElementsByClassName('ui-datepicker-current')[0]
          .addEventListener('click', () => {
            spinnerTrue('spinner1');
            inputCalendar.value = moment()
              .tz('Europe/Madrid')
              .format('DD / MM / YYYY');
            setParameter(
              'date',
              moment().tz('Europe/Madrid').format('DD-MM-YYYY')
            );
            renderGraphAgain();
            renderTabs();
          });
      });
      let dateForDatepicker = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');
      let dateURL = urlParams.get('date');
      let programURL = urlParams.get('program');
      let aggURL = urlParams.get('agg');
      if (!dateURL) {
        setParameter('date', dateForDatepicker);
        selectedDate = moment(dateForDatepicker, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        );
      } else {
        selectedDate = moment(dateURL, 'DD-MM-YYYY').format('YYYY-MM-DD');
      }
      if (!programURL) {
        setParameter('program', 'P48');
      }
      if (!aggURL) {
        setParameter('agg', 'hour');
      }
      selectedProgram = urlParams.get('program');
      selectedAgg = urlParams.get('agg');

      updateMobileSelectorOpts(selectedAgg);
      numberOfDaysInMonth = moment(selectedDate, 'YYYY-MM-DD').daysInMonth();
      spinnerTrue('spinner1');
      document
        .getElementById('selectOne')
        .setAttribute('value', selectedProgram);

      document.getElementById('selectTwo').setAttribute('value', selectedAgg);

      document
        .querySelector('.esios-layout__breadcrumbs__wrapper')
        .classList.add('esios-layout__breadcrumbs__wrapper--balance');

      // Get currently date
      let setFormatDate = selectedDate.split('-');
      const [year, month, day] = setFormatDate;

      // Extramos lo datos a mostrar en la modal
      let modalText = await servicesBalance.modalData(
        state.exampleReducer.currentLang,
        `${year}-${month}-${day}`
      );
      let idseccion = 0;
      const programaIdMap = {
        PBF: 1,
        PHF1: 2,
        PHF2: 3,
        PHF3: 4,
        PHF4: 5,
        PHF5: 6,
        PHF6: 7,
        PHF7: 8,
        PHFC: 9,
        PVP: 10,
      };

      if (selectedProgram in programaIdMap) {
        idseccion = programaIdMap[selectedProgram];
      }
      let lists = document.querySelectorAll('.esios-listEnergyItems__list');
      energyItems = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        categories.map((item) => item.indicators[idseccion]),
        selectedAgg,
        selectedDate
      );
      finalItems = [];
      let arrayOfEnergies = categories.map((el) => el.name);
      energyItems.forEach((element, i) => {
        let energies = element.short_name;
        let energiesList = arrayOfEnergies[i];

        if (energies.toUpperCase() === energiesList.toUpperCase()) {
          finalItems.push(energyItems[i]);
        } else {
          let noMatch = energyItems.find(
            (item) =>
              item.short_name.toUpperCase() === energiesList.toUpperCase()
          );

          if (energiesList.charAt(0).toUpperCase() === 'A') {
            finalItems.push(
              energyItems.find(
                (el) => el.short_name.charAt(0).toUpperCase() === 'A'
              )
            );
          } else if (noMatch == undefined || null) {
            finalItems.push(energyItems[i]);
          } else {
            finalItems.push(noMatch);
          }
        }
      });

      let tempIdOrderProgram = balanceObjects.programs.filter(
        (el) => el.name === selectedProgram
      );
      let idOrderArr = tempIdOrderProgram[0].indicators;
      let filterGraph = idOrderArr.filter((el, index) => index < 19);
      const orderedBarData = Utils.orderObjectsArrayByKey(
        energyItems,
        idOrderArr,
        'id'
      );
      let listNames = orderedBarData.map((el) => {
        return el;
      });

      let htmlContent = listNames
        .map((item, i) => {
          return `
            <li
              class="esios-listEnergyItems__item"
              value="${item.short_name}"
            >
              <span
                class=${
                  categories[i].legendtype == 'circle'
                    ? 'esios-listEnergyItems__decorator'
                    : 'esios-listEnergyItems__line'
                }
                style="background:${categories[i].color};"
              ></span>
              <p>
                ${
                  item.id === 10025
                    ? `${i18n.t('programs_adjustment')}`
                    : item.short_name
                }
              </p>
            </li>
          `;
        })
        .join('');

      lists.forEach((el) => {
        el.innerHTML = htmlContent;
      });

      let programSelected = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0].indicators;

      //  Extramos los valores de la gráfica
      let graphData = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        programSelected,
        selectedAgg,
        selectedDate
      );
      // Extraemos los datos que se van a mostrar por defecto en la tabla
      tabs = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        programSelected,
        selectedAgg,
        selectedDate
      );

      document.getElementById('selectOne').innerText = selectedProgram;
      let selectionFor;
      if (selectedAgg === 'hour') {
        selectionFor = i18n.t('daily_f').toUpperCase();
      } else if (selectedAgg === 'day') {
        selectionFor = i18n.t('monthly').toUpperCase();
      } else if (selectedAgg === 'month') {
        selectionFor = i18n.t('yearly').toUpperCase();
      }
      document.getElementById('selectTwo').innerText = selectionFor;
      document.getElementById('selectTwo').value = selectionFor;
      // Cada vez que el calendario cambia de valor, este se retorna y se vuelve a renderizar la tabla

      inputCalendar.value = moment(selectedDate, 'YYYY-MM-DD').format(
        'DD / MM / YYYY'
      );
      $(document).on('change', '#datepicker', async () => {
        let divCalendar = document.querySelector('.is-day-selectable');
        inputCalendar.setAttribute('disabled', 'true');
        divCalendar.classList.add('disabled');
        spinnerTrue('spinner1');
        let newDate = moment(inputCalendar.value, 'DD/MM/YYYY').format(
          'DD-MM-YYYY'
        );
        setParameter('date', newDate);

        // let tableWidth =
        //   window.innerWidth ||
        //   document.documentElement.clientWidth ||
        //   document.body.clientWidth;
        // if (tableWidth >= 540) {
        //   await renderGraphAgain();
        //   await renderTabs();
        // } else {
        renderComponentMobile();
        // }
        updateMobileSelectorOpts(selectedAgg);

        return inputCalendar.value;
      });

      let renderTabFunc;
      if (selectedAgg === 'hour') {
        renderTabFunc = updateTabDay(
          tabs,
          dayFirstTabValue,
          daySecondTabValue,
          daythirdTabValue,
          dayfourthTabValue
        );
      } else if (selectedAgg === 'day') {
        renderTabFunc = updateTabMonth(
          tabs,
          monthFirstTabValue,
          monthSecondTabValue,
          monthThirdTabValue,
          getArrayOfFourTabDays([24, 25, 26, 27, 28, 29, 30], 1)
        );
      } else if (selectedAgg === 'month') {
        renderTabFunc = updateTabYear(tabs, dataValue, sndTabValue);
      }
      // Se renderiza la tabla con los valores por defecto
      componentTab.render(renderTabFunc, '#tabwithcontent');
      componentSelects.render(selectMobileSelector, '#mobile__selector');
      const selectedColumn = document
        .querySelector('.mobile__selector_id')
        .getAttribute('value');

      // Se añade la modal al objeto toolbar
      obToolbar = [
        {
          id: 1,
          slug: i18n.t('routes.scheduled-generation-balance'),
          params: 'program=P48&agg=hour',
          info: true,
          share: true,
          favorite: true,
          favId: 24,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10027?compare_indicators=10141,10142,10143,10144,10145,10146,10147,10148,10149,10279&start_date=${moment(
              selectedDate,
              'YYYY-MM-DD'
            ).format('DD-MM-YYYYTHH:mm')}&geoids=`,
          textModal: `<div class="esios-balance__title col-lg-12 col-md-12 col-xs-12">
                        <h1>${i18n.t('balance').toUpperCase()}</h1>
                      </div>
                      ${modalText}`,
        },
      ];

      const exportData = await servicesBalance.exportData(
        state.exampleReducer.currentLang,
        `${year}-${month}-${day}`
      );
      let dataForExport = {
        description: modalText,
        indicators: tabs.map((el) => {
          return {
            ...el,
            print_values: el.values,
          };
        }),
        ...exportData,
      };
      setInfoModal(
        0,
        dataForExport,
        templateShareModal(
          'scheduled-generation-balance',
          window.location.search,
          false,
          {
            height: 1985,
            width: '100%',
          }
        ),
        'all'
      );

      const selectedPrograms = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0];
      // Renderizamos la gráfica
      await renderGraph(
        graphData,
        selectedPrograms,
        categories,
        selectedAgg,
        selectedProgram
      );
      spinnerNone('spinner1');
      if (tabs[0].values.length === 0) {
        document.querySelector('#balance-graph svg')?.classList.add('hidden');
        document
          .getElementById('balance-graph')
          .classList.add('no-data-graphic-balances');
        document
          .getElementById('balancesNoDataRect')
          .classList.remove('hidden');
      } else {
        document
          .getElementById('balance-graph')
          .classList.remove('no-data-graphic-balances');
        document.getElementById('balancesNoDataRect').classList.add('hidden');
      }
      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(selectedColumn, selectedAgg);
      }
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--balance');
      document
        .querySelector('.esios-layout__footer-image')
        .classList.add('esios-layout__footer-image--balance');
      document
        .querySelector('.esios-layout')
        .classList.add('esios-layout--balance');

      renderSuccess = true;
    } catch (error) {
      console.error(error);
      renderSuccess = false;
    } finally {
      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.removeAttribute('disabled');
      divCalendar.classList.remove('disabled');
    }
  };

  let renderComponentMobile = async () => {
    const state = store.getState();
    try {
      templateBalance(handlers, programs, categories, rangeDate, itemSelected);

      const inputCalendar = document.querySelector('#datepicker');

      let dateForDatepicker = moment(
        document.getElementById('datepicker').value,
        'DD / MM / YYYY'
      ).format('DD-MM-YYYY');
      let dateURL = urlParams.get('date');
      let programURL = urlParams.get('program');
      let aggURL = urlParams.get('agg');
      if (!dateURL) {
        setParameter('date', dateForDatepicker);
        selectedDate = moment(dateForDatepicker, 'DD-MM-YYYY').format(
          'YYYY-MM-DD'
        );
      } else {
        selectedDate = moment(dateURL, 'DD-MM-YYYY').format('YYYY-MM-DD');
      }
      if (!programURL) {
        setParameter('program', 'P48');
      }
      if (!aggURL) {
        setParameter('agg', 'hour');
      }
      selectedProgram = urlParams.get('program');
      selectedAgg = urlParams.get('agg');

      updateMobileSelectorOpts(selectedAgg);
      numberOfDaysInMonth = moment(selectedDate, 'YYYY-MM-DD').daysInMonth();
      spinnerTrue('spinner1');
      document
        .getElementById('selectOne')
        .setAttribute('value', selectedProgram);

      document.getElementById('selectTwo').setAttribute('value', selectedAgg);

      document
        .querySelector('.esios-layout__breadcrumbs__wrapper')
        .classList.add('esios-layout__breadcrumbs__wrapper--balance');

      // Get currently date
      let setFormatDate = selectedDate.split('-');
      const [year, month, day] = setFormatDate;

      // Extramos lo datos a mostrar en la modal
      let modalText = await servicesBalance.modalData(
        state.exampleReducer.currentLang,
        `${year}-${month}-${day}`
      );
      let idseccion = 0;
      const programaIdMap = {
        PBF: 1,
        PHF1: 2,
        PHF2: 3,
        PHF3: 4,
        PHF4: 5,
        PHF5: 6,
        PHF6: 7,
        PHF7: 8,
        PHFC: 9,
        PVP: 10,
      };

      if (selectedProgram in programaIdMap) {
        idseccion = programaIdMap[selectedProgram];
      }
      let lists = document.querySelectorAll('.esios-listEnergyItems__list');
      energyItems = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        categories.map((item) => item.indicators[idseccion]),
        selectedAgg,
        selectedDate
      );
      finalItems = [];
      let arrayOfEnergies = categories.map((el) => el.name);
      energyItems.forEach((element, i) => {
        let energies = element.short_name;
        let energiesList = arrayOfEnergies[i];

        if (energies.toUpperCase() === energiesList.toUpperCase()) {
          finalItems.push(energyItems[i]);
        } else {
          let noMatch = energyItems.find(
            (item) =>
              item.short_name.toUpperCase() === energiesList.toUpperCase()
          );

          if (energiesList.charAt(0).toUpperCase() === 'A') {
            finalItems.push(
              energyItems.find(
                (el) => el.short_name.charAt(0).toUpperCase() === 'A'
              )
            );
          } else if (noMatch == undefined || null) {
            finalItems.push(energyItems[i]);
          } else {
            finalItems.push(noMatch);
          }
        }
      });

      let tempIdOrderProgram = balanceObjects.programs.filter(
        (el) => el.name === selectedProgram
      );
      let idOrderArr = tempIdOrderProgram[0].indicators;
      let filterGraph = idOrderArr.filter((el, index) => index < 19);
      const orderedBarData = Utils.orderObjectsArrayByKey(
        energyItems,
        idOrderArr,
        'id'
      );
      let listNames = orderedBarData.map((el) => {
        return el;
      });

      let htmlContent = listNames
        .map((item, i) => {
          return `
            <li
              class="esios-listEnergyItems__item"
              value="${item.short_name}"
            >
              <span
                class=${
                  categories[i].legendtype == 'circle'
                    ? 'esios-listEnergyItems__decorator'
                    : 'esios-listEnergyItems__line'
                }
                style="background:${categories[i].color};"
              ></span>
              <p>
                ${
                  item.id === 10025
                    ? `${i18n.t('programs_adjustment')}`
                    : item.short_name
                }
              </p>
            </li>
          `;
        })
        .join('');

      lists.forEach((el) => {
        el.innerHTML = htmlContent;
      });

      let programSelected = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0].indicators;

      //  Extramos los valores de la gráfica
      let graphData = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        programSelected,
        selectedAgg,
        selectedDate
      );
      // Extraemos los datos que se van a mostrar por defecto en la tabla
      tabs = await servicesBalance.dataTabs(
        state.exampleReducer.currentLang,
        programSelected,
        selectedAgg,
        selectedDate
      );

      document.getElementById('selectOne').innerText = selectedProgram;
      let selectionFor;
      if (selectedAgg === 'hour') {
        selectionFor = i18n.t('daily_f').toUpperCase();
      } else if (selectedAgg === 'day') {
        selectionFor = i18n.t('monthly').toUpperCase();
      } else if (selectedAgg === 'month') {
        selectionFor = i18n.t('yearly').toUpperCase();
      }
      document.getElementById('selectTwo').innerText = selectionFor;
      document.getElementById('selectTwo').value = selectionFor;
      // Cada vez que el calendario cambia de valor, este se retorna y se vuelve a renderizar la tabla

      inputCalendar.value = moment(selectedDate, 'YYYY-MM-DD').format(
        'DD / MM / YYYY'
      );
      let renderTabFunc;
      if (selectedAgg === 'hour') {
        renderTabFunc = updateTabDay(
          tabs,
          dayFirstTabValue,
          daySecondTabValue,
          daythirdTabValue,
          dayfourthTabValue
        );
      } else if (selectedAgg === 'day') {
        renderTabFunc = updateTabMonth(
          tabs,
          monthFirstTabValue,
          monthSecondTabValue,
          monthThirdTabValue,
          getArrayOfFourTabDays([24, 25, 26, 27, 28, 29, 30], 1)
        );
      } else if (selectedAgg === 'month') {
        renderTabFunc = updateTabYear(tabs, dataValue, sndTabValue);
      }
      // Se renderiza la tabla con los valores por defecto
      componentTab.render(renderTabFunc, '#tabwithcontent');
      componentSelects.render(selectMobileSelector, '#mobile__selector');
      const selectedColumn = document
        .querySelector('.mobile__selector_id')
        .getAttribute('value');

      // Se añade la modal al objeto toolbar
      obToolbar = [
        {
          id: 1,
          slug: i18n.t('routes.scheduled-generation-balance'),
          params: 'program=P48&agg=hour',
          info: true,
          share: true,
          favorite: true,
          favId: 24,
          analysis: true,
          url: () =>
            `/${i18n.t('routes.lng')}/${i18n.t(
              'routes.analysis'
            )}/10027?compare_indicators=10141,10142,10143,10144,10145,10146,10147,10148,10149,10279&start_date=${moment(
              selectedDate,
              'YYYY-MM-DD'
            ).format('DD-MM-YYYYTHH:mm')}&geoids=`,
          textModal: `<div class="esios-balance__title col-lg-12 col-md-12 col-xs-12">
                        <h1>${i18n.t('balance').toUpperCase()}</h1>
                      </div>
                      ${modalText}`,
        },
      ];

      const exportData = await servicesBalance.exportData(
        state.exampleReducer.currentLang,
        `${year}-${month}-${day}`
      );
      let dataForExport = {
        description: modalText,
        indicators: tabs.map((el) => {
          return {
            ...el,
            print_values: el.values,
          };
        }),
        ...exportData,
      };
      setInfoModal(
        0,
        dataForExport,
        templateShareModal(
          'scheduled-generation-balance',
          window.location.search,
          false,
          {
            height: 1985,
            width: '100%',
          }
        ),
        'all'
      );

      const selectedPrograms = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0];
      // Renderizamos la gráfica
      await renderGraph(
        graphData,
        selectedPrograms,
        categories,
        selectedAgg,
        selectedProgram
      );
      spinnerNone('spinner1');
      if (tabs[0].values.length === 0) {
        document.querySelector('#balance-graph svg')?.classList.add('hidden');
        document
          .getElementById('balance-graph')
          .classList.add('no-data-graphic-balances');
        document
          .getElementById('balancesNoDataRect')
          .classList.remove('hidden');
      } else {
        document
          .getElementById('balance-graph')
          .classList.remove('no-data-graphic-balances');
        document.getElementById('balancesNoDataRect').classList.add('hidden');
      }
      let tableWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (tableWidth <= 540) {
        renderColumnSelected(selectedColumn, selectedAgg);
      }
      document
        .querySelector('.esios-layout__wrap')
        .classList.add('esios-layout__wrap--balance');
      document
        .querySelector('.esios-layout__footer-image')
        .classList.add('esios-layout__footer-image--balance');
      document
        .querySelector('.esios-layout')
        .classList.add('esios-layout--balance');

      renderSuccess = true;
    } catch (error) {
      console.error(error);
      renderSuccess = false;
    } finally {
      let inputCalendar = document.querySelector('#datepicker');
      let divCalendar = document.querySelector('.is-day-selectable');
      inputCalendar.removeAttribute('disabled');
      divCalendar.classList.remove('disabled');
    }
  };

  let postRenderComponent = async () => {
    const state = store.getState();
    context.name = state.exampleReducer.name;
  };

  let init = () => {
    //Call pre render component
    preRenderComponent();

    // Call render component
    renderComponent();

    // Call post render component if it has been rendered
    renderSuccess ? postRenderComponent() : '';
  };

  let renderGraphAgain = async () => {
    const state = store.getState();
    const time = Date.now();
    const today = new Date(time);
    const dateOfToday = today.toLocaleDateString();
    let setFormatDate = dateOfToday.split('/');
    const [day, month, year] = setFormatDate;
    let selectedProgram = urlParams.get('program');
    selectedAgg = urlParams.get('agg');
    selectedDate = urlParams.get('date')
      ? moment(urlParams.get('date'), 'DD-MM-YYYY').format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    try {
      let shareData = window.location.search;
      let programSelected = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0].indicators;

      let graphData = await servicesBalance
        .dataTabs(
          state.exampleReducer.currentLang,
          programSelected,
          selectedAgg,
          selectedDate
        )
        .then((data) => {
          return data;
        });
      let selectedPrograms = programs.filter((item) => {
        if (item.name === selectedProgram) {
          return item;
        }
      })[0];

      // Renderizamos la gráfica
      document.querySelector('#balance-graph svg')?.remove();
      await renderGraph(
        graphData,
        selectedPrograms,
        categories,
        selectedAgg,
        selectedProgram
      );
      if (graphData[0].values.length === 0) {
        document.querySelector('#balance-graph svg')?.classList.add('hidden');
        document
          .getElementById('balance-graph')
          .classList.add('no-data-graphic-balances');
        document
          .getElementById('balancesNoDataRect')
          .classList.remove('hidden');
      } else {
        document
          .getElementById('balance-graph')
          .classList.remove('no-data-graphic-balances');
        document.getElementById('balancesNoDataRect').classList.add('hidden');
      }
      const exportData = await servicesBalance.exportData(
        state.exampleReducer.currentLang,
        selectedDate
      );
      let modalText = await servicesBalance.modalData(
        state.exampleReducer.currentLang,
        selectedDate
      );
      let dataForExport = {
        description: modalText,
        indicators: graphData.map((el) => {
          return {
            ...el,
            print_values: el.values,
          };
        }),
        ...exportData,
      };

      setInfoModal(
        0,
        dataForExport,
        templateShareModal('scheduled-generation-balance', shareData),
        'share'
      );
      updateTabFromMobile(graphData);
      spinnerNone('spinner1');
    } catch (error) {
      console.error(error);
    }
  };

  return {
    render: init,
  };
})();

export { componentBalance };

export const renderGraph = async (
  data,
  selectedPrograms,
  categories,
  agg,
  selectedProgram,
  dateDatepicker
) => {
  let { date } = getUrlParams();
  if (!date) {
    date = moment(
      document.getElementById('datepicker').value,
      'DD / MM / YYYY'
    ).format('DD-MM-YYYY');
  }

  if (dateDatepicker) {
    date = dateDatepicker;
  }

  const completedData = await servicesBalance.completeData(
    i18n.t('routes.lng'),
    `${date.split('-')[2]}-${date.split('-')[1]}-${date.split('-')[0]}`
  );
  const graphData = data;
  const filteredCategory = categories.filter((el) => el.order === 30)[0]
    .indicators;
  const filterTest = selectedPrograms.indicators;
  const newDataFiltered = graphData.filter((el) => filterTest.includes(el.id));
  let idOrderArr = balanceObjects.orderList[selectedProgram];
  let filterGraph = idOrderArr.filter((el, index) => index < 22);
  const orderedBarData = Utils.orderObjectsArrayByKey(
    newDataFiltered,
    idOrderArr,
    'id'
  );
  const newD = orderedBarData.map((el, index) => {
    const values = el.values.map((obj, num) => {
      return {
        x: new Date(obj.datetime),
        hourOrder:
          el.values.length === 25 && num >= 3 && agg === 'hour'
            ? calculateOrder(
                moment(new Date(obj.datetime))
                  .tz('Europe/Madrid')
                  .format('DD-MM-YYYYTHH'),
                agg
              ) + 1
            : calculateOrder(
                moment(new Date(obj.datetime))
                  .tz('Europe/Madrid')
                  .format('DD-MM-YYYYTHH'),
                agg
              ),
        y: obj.value,
      };
    });
    return {
      values,
      id: el.id,
      short_name: el.short_name,
      name: el.short_name,
      geo_id: el.geos[0]?.geo_id,
      color: balanceObjects.colorList[index] || '#333333',
      type: 'bar',
      interpolation: 'linear',
    };
  });

  const lineData = newD.filter((el) => filteredCategory.includes(el.id));
  const barData = newD.filter((el) => filterGraph.includes(el.id));
  const newLine = lineData.map((el) => {
    return {
      id: el.id,
      color: el.color,
      type: 'line',
      dots: false,
      interpolation: 'basis',
      values: el.values,
    };
  })[0];

  const dataFinal = [
    {
      id: 1,
      type: 'bar',
      grouped: true,
      data: barData,
    },
    newLine,
  ];
  let newArrayToOrder = [...barData, newLine];
  let seriesHTML = document.getElementById('balance-graph');
  let aggValue, timeType;
  var rangeNumber = 24;
  if (agg === 'hour') {
    seriesHTML.classList.remove('years');
    aggValue = [d3.time.hours, 1];
    timeType = 'hour';
    rangeNumber =
      calculateHoursPerDay(moment(date, 'DD-MM-YYYY')) === 25 ? 25 : 24;
  } else if (agg === 'day') {
    seriesHTML.classList.remove('years');
    aggValue = [d3.time.days, 1];
    timeType = 'day';
    rangeNumber = moment(date, 'DD-MM-YYYY').daysInMonth();
  } else if (agg === 'month') {
    seriesHTML.classList.add('years');
    aggValue = [d3.time.months, 1];
    timeType = 'month';
    rangeNumber = 12;
  }

  let finalDATA = getByMonth(selectedProgram, completedData);

  let newFinalData = finalDATA.map((el) => {
    return {
      ...el,
      values: fillSimpleVals(rangeNumber),
    };
  });
  let lasData = newFinalData
    .map((el) => {
      let finded = newArrayToOrder.filter((or) => or.id == el.id)[0]?.values;
      finded.forEach((val) => {
        el.values[val.hourOrder] = {
          ...el.values[val.hourOrder],
          value: val.y,
          y: val.y,
          color: el.color,
          name: el.name,
        };
      });

      return el;
    })
    .sort((a, b) => {
      if (a.order > b.order) {
        return -1;
      }
      if (a.order < b.order) {
        return 1;
      }
      return 0;
    });

  if (window.location.pathname.includes(i18n.t('routes.myesios'))) {
    Utils.spinnerStatus('spinner24', false);
    document.querySelector(`#char24`).style.opacity = '1';
  }

  renderBalanceGraph(lasData, i18n.t(timeType));
};

const renderColumnSelected = (selected, agg) => {
  let selectedFor25h = selected;
  selected = parseInt(selected);
  let select = selected;
  document.querySelectorAll('.ui-tabs-panel').forEach((e) => {
    e.style.display = 'none';
  });
  if (agg === 'hour') {
    document.querySelector('.selector-title').innerText = i18n
      .t('hour')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numberHours = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
    if (numberHours == 23 && selected > 4) {
      select = select + 1;
    }
    if (
      (numberHours == 25 && selectedFor25h == '2b') ||
      (numberHours == 25 && selected > 2)
    ) {
      selected = selected + 1;
    }
    if (select <= 5) {
      document.getElementById('table-0').style.display = 'block';
    } else if (select > 5 && select <= 11) {
      selected = select - 6;
      document.getElementById('table-1').style.display = 'block';
    } else if (select > 11 && select <= 17) {
      selected = select - 12;
      document.getElementById('table-2').style.display = 'block';
    } else if (select > 17 && select <= 23) {
      selected = select - 18;
      document.getElementById('table-3').style.display = 'block';
    }
  } else if (agg === 'day') {
    document.querySelector('.selector-title').innerText = i18n
      .t('day')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numberOfDays = moment(date, 'DD-MM-YYYY').daysInMonth();
    if (select <= 7) {
      document.getElementById('table-0').style.display = 'block';
    } else if (select > 7 && select <= 15) {
      selected = select - 8;
      document.getElementById('table-1').style.display = 'block';
    } else if (select > 15 && select <= 23) {
      selected = select - 16;
      document.getElementById('table-2').style.display = 'block';
    } else if (select > 23 && select <= numberOfDays) {
      selected = select - 24;
      document.getElementById('table-3').style.display = 'block';
    }
  } else if (agg === 'month') {
    document.querySelector('.selector-title').innerText = i18n
      .t('month')
      .toUpperCase();

    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    if (select <= 5) {
      document.getElementById('table-0').style.display = 'block';
    } else if (select > 5 && select <= 12) {
      selected = select - 6;
      document.getElementById('table-1').style.display = 'block';
    }
  }
  document.querySelectorAll('table .column-with-data').forEach((e) => {
    e.style.display = 'none';
  });
  document.querySelectorAll('.coltable-' + selected).forEach((e) => {
    e.style.display = 'initial';
    e.classList.add('val_numbers');
  });
};

export const getArrayOfHoursForBalance = (type) => {
  let arr = [];
  if (type === 'hour') {
    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numberHours = calculateHoursPerDay(moment(date, 'DD-MM-YYYY'));
    for (let index = 0; index < numberHours; index++) {
      let valor = index;
      if (numberHours == 25 && index > 2) {
        valor = index - 1;
      }
      if (numberHours == 23 && index > 1) {
        arr.push({
          label: `${valor + 1}-${valor + 2} h`,
          value: `${valor}`,
        });
      } else if (numberHours == 25 && index == 2) {
        arr.push({
          label: `${valor}-${valor}b h`,
          value: `${valor}`,
        });
      } else if (numberHours == 25 && index == 3) {
        arr.push({
          label: `${valor}b-${valor + 1} h`,
          value: `${valor}b`,
        });
      } else {
        arr.push({
          label: `${valor}-${valor + 1} h`,
          value: `${valor}`,
        });
      }
    }
  } else if (type === 'day') {
    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }
    let numbersDaysInMonth = moment(date, 'DD-MM-YYYY').daysInMonth();
    for (let index = 1; index <= numbersDaysInMonth; index++) {
      let valor = index;

      arr.push({
        label: `${valor}`,
        value: `${valor - 1}`,
      });
    }
  } else if (type === 'month') {
    let { date } = getUrlParams();
    if (!date) {
      date = moment().format('DD-MM-YYYY');
    }

    for (let index = 1; index <= 12; index++) {
      let valor = index;
      let tempArr = Utils.getArrayOfMonths();
      arr.push({
        label: tempArr[valor - 1].label,
        value: `${valor - 1}`,
      });
    }
  }
  return arr;
};

export const renderBalanceGraph = (lasdata, timeType, opts) => {
  let { date, agg } = getUrlParams();
  if (!date) {
    date = moment().format('DD-MM-YYYY');
  }

  if (!agg) {
    agg = 'hour';
  }
  let val23h = calculateHoursPerDay(date);
  if (val23h === 23 && agg === 'hour') {
    lasdata = lasdata.map((el) => {
      return {
        ...el,
        values: el.values.filter((ob) => ob.order !== 2),
      };
    });
  }

  var elem = opts?.elem || '#balance-graph';
  $(opts?.elem || '#balance-graph').html('');
  var margin = opts?.margin
    ? opts?.margin
    : { top: 20, right: 20, bottom: 40, left: 70 };
  var w = $(elem).parent().width(),
    h = opts?.height || 680;
  var rangeStep = 1;
  var numTicks = opts?.yticks || 15;

  var rangeDate = agg || 'hour';

  if (window.innerWidth >= 540) {
    rangeStep = 1;

    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  } else if (window.innerWidth <= 540 && !opts?.bottom?.prefix) {
    h = 400;
    numTicks = 9;
    rangeStep = 3;
    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  }

  if (
    window.innerWidth <= 540 &&
    val23h === 23 &&
    timeType === i18n.t('hour')
  ) {
    h = 400;
    numTicks = 10;
    rangeStep = 1;
    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  }
  if (window.innerWidth >= 540) {
    h = opts?.height || 680;
  }

  (w = w - margin.left - margin.right), (h = h - margin.top - margin.bottom);

  var x = d3.scale.ordinal().rangeRoundBands([0, w], 0.4);
  var y = d3.scale.linear().range([h, 0]);
  var data = lasdata;
  var dataDefault = _.filter(data, function (cat) {
    return !cat.demanda && !cat.area0 && !cat.area1;
  });

  var demandaLine = _.filter(data, function (cat) {
    return cat.demanda;
  });

  var dataDefault2 = _.filter(data, function (cat) {
    return cat;
  });

  var dataDemanda = _.findWhere(data, { demanda: true });

  dataDemanda?.values.map(function (d) {
    d.y = opts?.type === 'multi' ? d.y : Math.abs(d.y);
    if (d.value !== null) {
      d.value = opts?.type === 'multi' ? d.y : Math.abs(d.value);
    }
  });
  var dataDemandaArea0Pos = _.findWhere(data, { area0: true });
  dataDemandaArea0Pos?.values.map(function (d) {
    return d.y;
  });

  var dataDemandaArea0 = _.findWhere(data, { area0: true });
  dataDemandaArea0?.values.map(function (d) {
    d.y = Math.abs(d.y);
    if (d.value !== null) {
      d.value = Math.abs(d.value);
    }
  });

  var dataDemandaArea1Neg = _.findWhere(data, { area1: true });
  dataDemandaArea1Neg?.values.map(function (d) {
    return d.y;
  });

  var dataDemandaArea1 = _.findWhere(data, { area1: true });
  dataDemandaArea1?.values.map(function (d) {
    d.y = d.y;
    if (d.value !== null) {
      d.value = d.value;
    }
  });
  var svgBars = d3
    .select(elem)
    .append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  var stack = d3.layout
    .stackCustom()
    .values(function (d) {
      return d.values;
    })
    .offset(function (data) {
      var j = -1;
      var len = data[0].length;
      var y0 = [];
      while (++j < len) {
        y0[j] = [0, 0];
      }
      return y0;
    })
    .out(function (d, y0, change, y) {
      var posOrNeg = change >= 0 ? 0 : 1;
      y0[posOrNeg] += change;
      d.y0 = y0.slice();
      d.y = y || 0;
    });

  stack(dataDefault);
  var demandaMax = dataDemanda?.values
    ? d3.max(dataDemanda?.values, function (d) {
        return Math.abs(d.value);
      })
    : 0;
  var demandaMaxPosNeg = dataDemanda?.values
    ? d3.max(dataDemanda?.values, function (d) {
        return d.value;
      })
    : 0;
  var demandaarea0 =
    dataDemandaArea0 &&
    d3.max(dataDemandaArea0.values, function (d) {
      return Math.abs(d.value);
    });
  var demandaarea1 =
    dataDemandaArea1 &&
    d3.max(dataDemandaArea1.values, function (d) {
      return d.value;
    });

  var yMax = d3.max(dataDefault, function (type) {
    return d3.max(type.values, function (d) {
      return Math.abs(d.y - d.y0[0]);
    });
  });
  var yMaxPosNeg = d3.max(dataDefault, function (type) {
    return d3.max(type.values, function (d) {
      return Math.abs(d.y - d.y0[0]);
    });
  });
  var yMinComplete = d3.min(dataDefault2, function (type) {
    return d3.min(type.values, function (d) {
      return d.y;
    });
  });
  var yMin = d3.min(dataDefault, function (type) {
    return d3.min(type.values, function (d) {
      return d.y + d.y0[1];
    });
  });

  if (demandaMax > yMax) {
    yMax = demandaMax;
    if (opts?.type === 'multi' && demandaMaxPosNeg > yMaxPosNeg) {
      yMax = demandaMaxPosNeg;

      if (demandaarea0 > demandaMaxPosNeg) {
        yMax = demandaarea0;
      }
    }
    if (opts?.type === 'multi' && demandaMaxPosNeg < yMaxPosNeg) {
      yMax = yMaxPosNeg;
      if (demandaarea0 > yMaxPosNeg) {
        yMax = demandaarea0;
      }
    }
  }
  var rangeData = dataDefault[0]?.values.map(function (d) {
    return d.x;
  });
  var xAxisData = d3.range(
    rangeData[0],
    rangeData[rangeData.length - 1] + 1,
    rangeStep
  );
  var xAxisDataDef = d3.range(
    rangeData[0],
    rangeData[rangeData.length - 1] + 1,
    1
  );
  if (val23h === 23 && timeType === i18n.t('hour')) {
    xAxisData.splice(2, 1);
  }
  x.domain(rangeData);
  let finalY = 0;
  if (parseInt(yMin) > parseInt(yMinComplete)) {
    finalY = yMinComplete;
  } else {
    finalY = yMin;
  }

  // finalY variable is the mayor of bars and line graphs
  if (opts?.fit) {
    let vals = [];
    let valsMin = [];
    dataDefault.map((el) => {
      el.values.map((val, ind) => {
        if (val.value > 0) {
          vals[ind] = vals[ind] ? vals[ind] + val.value : 0 + val.value;
        } else {
          valsMin[ind] = valsMin[ind]
            ? valsMin[ind] + val.value
            : 0 + val.value;
        }
      });
    });
    let areaVals = [];
    dataDemandaArea0Pos?.values.map((val, ind) => {
      if (val.value > 0) {
        areaVals.push(val.value);
      }
    });
    let areaValsMin = [];
    dataDemandaArea1Neg?.values.map((val, ind) => {
      if (val.value < 0) {
        areaValsMin.push(val.value);
      }
    });

    let valLine = [];
    let valLineMin = [];
    demandaLine.map((el) => {
      el.values.map((val, ind) => {
        if (val.value > 0) {
          valLine.push(val.value);
        } else {
          valLineMin.push(val.value);
        }
      });
    });

    areaVals = areaVals?.filter((el) => el != null);
    areaValsMin = areaValsMin?.filter((el) => el != null);
    vals = vals?.filter((el) => el != null);
    valsMin = valsMin?.filter((el) => el != null);

    valLine = valLine?.filter((el) => el != null);
    valLineMin = valLineMin?.filter((el) => el != null);

    let maxyFinal = vals.length == 0 ? 0 : Math.max(...vals);
    let maxyFinalArea = areaVals.length == 0 ? 0 : Math.max(...areaVals);
    let minyFinal = valsMin.length == 0 ? 0 : Math.min(...valsMin);
    let minyFinalArea = areaValsMin.length == 0 ? 0 : Math.min(...areaValsMin);
    let minyFinalLineMax = valLine.length == 0 ? 0 : Math.max(...valLine);
    let minyFinalLine = valLineMin.length == 0 ? 0 : Math.min(...valLineMin);

    if (maxyFinalArea > maxyFinal) {
      maxyFinal = maxyFinalArea;
    } else {
      maxyFinal = maxyFinal;
    }

    if (minyFinalLineMax > maxyFinal) {
      maxyFinal = minyFinalLineMax;
    } else {
      maxyFinal = maxyFinal;
    }

    if (minyFinalArea < minyFinal) {
      minyFinal = minyFinalArea;
    } else {
      minyFinal = minyFinal;
    }
    if (minyFinalLine < minyFinal) {
      minyFinal = minyFinalLine;
    } else {
      minyFinal = minyFinal;
    }
    y.domain([minyFinal, maxyFinal]).nice(numTicks);
  } else {
    y.domain([finalY, yMax]).nice(numTicks);
  }

  let nodata = false;
  if (yMax == 0 && finalY == 0) {
    nodata = true;
  }
  var xAxis = d3.svg
    .axis()
    .scale(x)
    .tickValues(xAxisData)
    .tickFormat(function (v, i) {
      if (timeType !== i18n.t('hour')) {
        return v + 1;
      } else {
        let prefix = opts?.bottom?.prefix || '';
        if (
          val23h === 25 &&
          v >= 3 &&
          timeType === i18n.t('hour') &&
          !opts?.special
        ) {
          if (window.innerWidth <= 540 && v >= 3 && v <= 8) {
            return prefix + v;
          }
          return prefix + v - 1;
        } else if (
          val23h === 23 &&
          timeType === i18n.t('hour') &&
          window.innerWidth <= 540 &&
          !opts?.special
        ) {
          if (v % 3 === 0 || i === 0) {
            return v;
          }
        } else if (
          val23h === 25 &&
          timeType === i18n.t('hour') &&
          opts?.special
        ) {
          if (v % 2 === 0) {
            if (v >= 3) {
              if (v < 11) {
                return prefix + (v - 1);
              } else {
                return v - 1;
              }
            } else {
              return prefix + v;
            }
          }
        } else if (
          val23h === 23 &&
          timeType === i18n.t('hour') &&
          opts?.special
        ) {
          if (v % 2 === 1) {
            if (v == 1) {
              return '';
            }
            if (v >= 10) {
              return v;
            } else {
              return prefix + v;
            }
          } else {
            if (v === 0) {
              return prefix + v;
            }
          }
        } else {
          if (prefix === '0') {
            if (v % 2 === 0) {
              if (v < 10) {
                return prefix + v;
              } else {
                return v;
              }
            } else {
              return '';
            }
          } else {
            return prefix + v;
          }
        }
      }
    })
    .orient('bottom');

  var xAxisDef = d3.svg
    .axis()
    .scale(x)
    .tickValues(xAxisDataDef)
    .tickFormat(function (v, i) {
      if (timeType !== i18n.t('hour')) {
        return v + 1;
      }
    })
    .orient('bottom');

  var yAxis = d3.svg
    .axis()
    .scale(y)
    .tickFormat(function (v) {
      return Utils.toNumber(v, 0);
    })
    .orient('left')
    .ticks(numTicks);

  var yAxisCustom = d3.svg
    .axis()
    .scale(y)
    .ticks(numTicks)
    .tickSize(w)
    .tickFormat('')
    .orient('right');

  svgBars
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + h + ')')
    .call(xAxis);

  svgBars
    .append('g')
    .style('fill', 'red')
    .attr('class', 'x axisDef')
    .attr('transform', 'translate(0,' + h + ')')
    .call(xAxisDef);

  svgBars
    .append('text')
    .attr('class', 'axisLabel x')
    .attr(
      'transform',
      `${
        opts?.bottom?.center
          ? 'translate(' + w / 2 + ' ,' + (h + margin.bottom - 5) + ')'
          : 'translate(' + -20 + ' ,' + (h + 18) + ')'
      }`
    )
    .style('text-anchor', 'middle')
    .style('text-transform', 'capitalize')
    .text(
      opts?.bottom?.hideHour
        ? ''
        : opts?.bottom?.label
        ? i18n.t(opts?.bottom?.label)
        : ''
    );

  svgBars
    .append('g')
    .attr('class', 'yaxis')
    .call(yAxis)
    .append('text')
    .attr('y', 3)
    .attr('dy', '.71em')
    .style('text-anchor', 'end');

  svgBars
    .append('text')
    .attr('class', 'axisLabel')
    .attr('transform', 'translate(-10 , -10)')
    .style('text-anchor', 'end')
    .text(opts?.left?.label === 'empty' ? '' : opts?.left?.label || 'MWh');

  svgBars
    .append('text')
    .attr('class', 'axisLeftCenterLabel')
    .text(opts?.leftCenter?.label || '');

  if (demandaMax >= 0) {
    var areaFunc = d3.svg
      .area()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d, a) {
        if (val23h == 23) {
          if (a > 1) {
            a = a + 1;
          }
        }
        if (val23h == 25) {
          return a > 23 ? x(d.x) + x.rangeBand() : x(d.x);
        }
        return a == 23 ? x(d.x) + x.rangeBand() : x(d.x);
      })
      .y1(function (d) {
        return y(d.y);
      })
      .y0(function (d) {
        return y(0);
      });

    var areaFuncNeg = d3.svg
      .area()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d, a) {
        if (val23h == 23) {
          if (a > 1) {
            a = a + 1;
          }
        }
        if (val23h == 25) {
          return a > 23 ? x(d.x) + x.rangeBand() : x(d.x);
        }
        return a == 23 ? x(d.x) + x.rangeBand() : x(d.x);
      })
      .y0(function (d) {
        return y(0);
      })
      .y1(function (d) {
        return y(d.y);
      });

    dataDemandaArea0 &&
      svgBars
        .append('g')
        .append('path')
        .attr('class', 'bar__line0')
        .attr('d', areaFunc(dataDemandaArea0.values))
        .attr('fill', `${opts?.areaColor?.positive || '#00b300'}`);

    dataDemandaArea1 &&
      svgBars
        .append('g')
        .append('path')
        .attr('class', 'bar__line1')
        .attr('d', areaFuncNeg(dataDemandaArea1.values))
        .attr('fill', `${opts?.areaColor?.negative || '#ffcc66'}`);
  }
  var bar = svgBars
    .selectAll('g.bars')
    .data(dataDefault)
    .enter()
    .append('svg:g')
    .attr('class', 'bars')
    .attr('transform', function () {
      return 'translate(0,0)';
    });
  let valsForArrOfBars = [];
  let barVals = d3.selectAll('.axisDef .tick');
  barVals[0].map((el) => {
    let pos = el?.getAttribute('transform').split('(')[1];
    pos = pos.split(',')[0];
    valsForArrOfBars.push(parseInt(pos));
  });

  var paddingPos = [];
  var padding = [];

  bar
    .selectAll('rect')
    .data(function (d) {
      return d.values;
    })
    .enter()
    .append('svg:rect')
    .attr('x', function (d) {
      return x(d.x);
    })
    .attr('y', function (d, i, e) {
      if (d.value > 0) {
        if (d.y0[1] === 0 && e === 0) {
          paddingPos[d.x + 1] = Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
        }
        if (paddingPos[d.x + 1] && e !== 0) {
          return y(d.y + d.y0[0]) + paddingPos[d.x + 1];
        } else {
          return y(d.y + d.y0[0]);
        }
      } else {
        if (d.y0[0] === 0 && e === 0) {
          padding[d.x] = Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
        }
        if (padding[d.x]) {
          return y(d.y0[1]) - padding[d.x];
        } else {
          return y(d.y0[1]);
        }
      }
    })
    .attr('height', function (d) {
      return Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
    })
    .attr('width', x.rangeBand())
    .attr('data-value', function (d) {
      return d.value;
    })
    .style('fill', function (d) {
      return d.color;
    });

  var gy = svgBars.append('g').attr('class', 'y axis').call(yAxisCustom);

  gy.selectAll('g')
    .attr('class', function (d) {
      var axisValue = Math.abs(d);
      return 'minor axis-line-' + axisValue;
    })
    .filter(function (d) {
      return d;
    });

  gy.selectAll('text').attr('x', 4).attr('dy', -4);
  if (demandaMax > 0) {
    let lineInterpolation = opts?.lineInterpolation
      ? opts.lineInterpolation
      : 'basis';
    var line = d3.svg
      .line()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d) {
        return x(d.x);
      })
      .y(function (d) {
        return y(d.y);
      })
      .interpolate(lineInterpolation);

    svgBars
      .append('g')
      .append('path')
      .attr('class', 'bar__line')
      .attr('d', line(dataDemanda?.values))
      .attr('stroke', '#00D9D9')
      .attr('fill', 'none');
  }

  if (opts?.multibar) {
    // TODO: CHECK this for trail
    let bg = svgBars
      .append('g')
      .style('pointer-events', 'all')
      .attr('class', 'background');
    bg.append('rect')
      .style('cursor', 'pointer')
      .attr('class', 'bg')
      .attr('x', '0')
      .attr('fill', 'none')
      .attr('width', w)
      .attr('height', h);

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let arr = opts.date.split(' / ');
    arr = opts.date.split('-');

    let currentPositionSelected = 23;
    if (arr[0] == day && arr[1] == month && arr[2] == year) {
      let date = new Date();
      let hours = date.getHours();
      currentPositionSelected = hours;
    }

    if (nodata) {
      currentPositionSelected = 0;
    }

    document.querySelector('#widget39 .mod-date').innerHTML = moment()
      .hours(currentPositionSelected)
      .minutes('00')
      .format('HH:mm');

    let lineTrail = bg
      .append('line')
      .attr('transform', 'translate(0,0)')
      .style('cursor', 'pointer')
      .style('stroke', '#333333')
      .style('stroke-width', 0.8)
      .attr('x1', valsForArrOfBars[currentPositionSelected])
      .attr('y1', 0)
      .attr('x2', valsForArrOfBars[currentPositionSelected])
      .attr('y2', h);

    let triangleTrail = bg
      .append('path')
      .attr(
        'transform',
        `translate(${valsForArrOfBars[currentPositionSelected] - 5},-8)`
      )
      .attr('class', 'trail-arrow')
      .attr('fill', '#7b7b7b')
      .attr('d', 'M 0 0 L 12 0 L 6 12 z');

    data.forEach((el, index) => {
      let elementValue = document.querySelector(`.legendbar-${el.id} .value`);
      let elementMagnitud = document.querySelector(
        `.legendbar-${el.id} .magnitud`
      );
      if (elementValue) {
        let numVal = data[index].values[currentPositionSelected].value;
        elementValue.innerHTML = Utils.toNumber(numVal, 0, 'comma') || '-';
      }
      if (elementMagnitud) {
        elementMagnitud.innerHTML = opts?.magnitud;
      }
    });

    function moveTrail() {
      var mouse = d3.mouse(this);

      var closest = valsForArrOfBars.reduce(function (prev, curr) {
        return Math.abs(curr - mouse[0]) < Math.abs(prev - mouse[0])
          ? curr
          : prev;
      });

      currentPositionSelected = valsForArrOfBars.findIndex(
        (el) => el == closest
      );
      lineTrail.attr('x1', closest).attr('x2', closest);
      triangleTrail.attr('transform', `translate(${closest - 6},-8)`);

      if (document.querySelector('#widget39 .mod-date')) {
        if (val23h == 25 && currentPositionSelected > 2) {
          currentPositionSelected = currentPositionSelected - 1;
        }
        document.querySelector('#widget39 .mod-date').innerHTML = moment()
          .hours(currentPositionSelected)
          .minutes('00')
          .format('HH:mm');
      }
      if (document.querySelector('#char39 .esios-toolbar-time__mod-date')) {
        if (val23h == 25 && currentPositionSelected > 2) {
          currentPositionSelected = currentPositionSelected - 1;
        }
        document.querySelector(
          '#char39 .esios-toolbar-time__mod-date'
        ).innerHTML = moment()
          .hours(currentPositionSelected)
          .minutes('00')
          .format('HH:mm');
      }

      data.forEach((el, index) => {
        let element = document.querySelector(`.legendbar-${el.id} .value`);
        let elementMagnitud = document.querySelector(
          `.legendbar-${el.id} .magnitud`
        );

        if (element) {
          let numVal = data[index].values.filter(
            (el) => el.order == currentPositionSelected
          )[0].value;
          element.innerHTML = Utils.toNumber(numVal, 0, 'comma') || '-';
        }
        if (elementMagnitud) {
          elementMagnitud.innerHTML = opts?.magnitud;
        }
      });
    }

    let svgBG = d3.select(`${elem} svg .bg`).data(data);
    svgBG.on('click', moveTrail);
    svgBG.on('mousedown', function () {
      svgBG.on('mousemove', moveTrail);
    });
    svgBG.on('mouseup', function () {
      svgBG.on('mousemove', null);
    });
    svgBG.on('mouseleave', null);
    // ! END OF TRAIL
  }
};

const getColour = (index) => {
  // Devuelve el color correspondiente al índice o proporciona un color predeterminado.
  const colors = [
    '#046BBC',
    '#F68709 ',
    '#9C9C9C',
    '#046BBC',
    '#F68709 ',
    '#9C9C9C',
  ]; // Puedes personalizar estos colores
  return colors[index % colors.length];
};

// Obtener mes y año de la variable date
const monthNamesEs = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sept',
  'Oct',
  'Nov',
  'Dic',
];

const monthNamesEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

const monthNames = i18n.language === 'es' ? monthNamesEs : monthNamesEn;

export const renderBalanceErniGraph = (lasdata, timeType, opts) => {
  let { date, agg } = getUrlParams();
  if (date == null) {
    date = opts.date;
  }
  // Obtener mes y año de la variable date
  const monthNamesEs = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sept',
    'Oct',
    'Nov',
    'Dic',
  ];

  const monthNamesEn = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthNames = i18n.language === 'es' ? monthNamesEs : monthNamesEn;
  // Obtener el mes actual
  const actualcurrentMonth = new Date().getMonth() + 1;
  const [day, month, year] = date.split('-').map(Number);
  const actualdate = new Date(year, month - 1, day);
  // Comparar el mes actual con el mes de la variable date
  if (actualcurrentMonth === actualdate.getMonth() + 1) {
    // Restar un mes a la variable date
    actualdate.setMonth(actualdate.getMonth() - 1);
  }
  if (actualdate.getMonth() + 1 > actualcurrentMonth) {
    // Restar un mes a la variable date
    //actualdate.setMonth(actualcurrentMonth - 1);
  }

  // Formatear la fecha resultante nuevamente a 'MM-DD-YYYY'
  const updatedDateString = `${String(actualdate.getDate()).padStart(
    2,
    '0'
  )}-${String(actualdate.getMonth() + 1).padStart(
    2,
    '0'
  )}-${actualdate.getFullYear()}`;
  date = updatedDateString;
  // Parsear la variable date a un objeto de fecha
  const dateObject = moment(date, 'DD-MM-YYYY').toDate();
  const iniDateObject = moment(dateObject).subtract(13, 'months').toDate();

  // Obtener mes y año de la variable date
  const currentMonth = dateObject.getMonth();
  const currentYear = dateObject.getFullYear();

  const inicurrentMonth = iniDateObject.getMonth();
  const inicurrentYear = iniDateObject.getFullYear();

  // Crear una función para formatear la fecha en el eje x
  const formatXAxisLabel = (month, year, w) => {
    if (w < 700) {
      return `${monthNames[month]}`;
    } else {
      return `${monthNames[month]}${' ' + year}`;
    }
  };

  agg = 'month';
  if (!date) {
    date = moment().format('DD-MM-YYYY');
  }

  if (!agg) {
    agg = 'hour';
  }
  let val23h = calculateHoursPerDay(date);
  if (val23h === 23 && agg === 'hour') {
    lasdata = lasdata.map((el) => {
      return {
        ...el,
        values: el.values.filter((ob) => ob.order !== 2),
      };
    });
  }

  var elem = opts?.elem || '#balance-graph';
  var margin = opts?.margin
    ? opts?.margin
    : { top: 20, right: 20, bottom: 40, left: 70 };
  var w = $(elem).parent().width(),
    h = 200;
  var rangeStep = 1;
  var numTicks = opts?.yticks || 15;

  var rangeDate = agg || 'hour';

  if (window.innerWidth >= 540) {
    rangeStep = 1;

    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  } else if (window.innerWidth <= 540 && !opts?.bottom?.prefix) {
    h = 300;
    numTicks = 9;
    rangeStep = 3;
    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  }

  if (
    window.innerWidth <= 540 &&
    val23h === 23 &&
    timeType === i18n.t('hour')
  ) {
    h = 300;
    numTicks = 10;
    rangeStep = 1;
    if (rangeDate === 'month') {
      rangeStep = 1;
    }
  }
  if (window.innerWidth >= 540) {
    h = opts?.height || 680;
  }

  (w = w - margin.left - margin.right), (h = h - margin.top - margin.bottom);

  // Configurar la escala x con el formato de etiqueta personalizado
  let mesesx = [];
  let mes = currentMonth;
  for (let i = 0; i < 13; i++) {
    mesesx.push(mes);
    mes = mes - 1;
    if (mes == -1) {
      mes = 11;
    }
  }

  const xAxisData = mesesx;
  var x = d3.scale
    .ordinal()
    .rangeRoundBands([0, w], 0.4)
    .domain(
      xAxisData.map((monthOffset) =>
        formatXAxisLabel(
          (monthOffset + 12) % 12,
          currentYear - Math.floor((monthOffset + 12) / 12),
          w
        )
      )
    );
  var y = d3.scale.linear().range([260, 0]);
  var data = lasdata;
  var dataDefault = _.filter(data, function (cat) {
    return !cat.demanda && !cat.area0 && !cat.area1;
  });

  var demandaLine = _.filter(data, function (cat) {
    return cat.demanda;
  });

  var dataDefault2 = _.filter(data, function (cat) {
    return cat;
  });

  var dataDemanda = _.findWhere(data, { demanda: true });

  dataDemanda?.values.map(function (d) {
    d.y = opts?.type === 'multi' ? d.y : Math.abs(d.y);
    if (d.value !== null) {
      d.value = opts?.type === 'multi' ? d.y : Math.abs(d.value);
    }
  });

  var dataimport = _.findWhere(data, { import: true });
  dataimport?.values.map(function (d) {
    d.y = Math.abs(d.y);
    if (d.value !== null) {
      d.value = Math.abs(d.value);
    }
  });

  var dataexport = _.findWhere(data, { export: true });
  dataexport?.values.map(function (d) {
    if (d.value !== null) {
      d.value = d.value;
    }
  });

  var dataDemandaArea0Pos = _.findWhere(data, { area0: true });
  dataDemandaArea0Pos?.values.map(function (d) {
    return d.y;
  });

  var dataDemandaArea0 = _.findWhere(data, { area0: true });
  dataDemandaArea0?.values.map(function (d) {
    d.y = Math.abs(d.y);
    if (d.value !== null) {
      d.value = Math.abs(d.value);
    }
  });

  var dataDemandaArea1Neg = _.findWhere(data, { area1: true });
  dataDemandaArea1Neg?.values.map(function (d) {
    return d.y;
  });

  var dataDemandaArea1 = _.findWhere(data, { area1: true });
  dataDemandaArea1?.values.map(function (d) {
    d.y = d.y;
    if (d.value !== null) {
      d.value = d.value;
    }
  });

  h = 360;
  let trh = 51;
  let trw = 30;
  if (w < 500) {
    trh = 30;
  }
  var svgBars = d3
    .select(elem)
    .append('svg')
    .attr('width', w + margin.left + margin.right)
    .attr('height', h + margin.top + margin.bottom)
    .attr('transform', 'translate(-30, 0)')
    .append('g')
    .attr('transform', 'translate(' + trh + ',' + trw + ')');

  var stack = d3.layout
    .stackCustom()
    .values(function (d) {
      return d.values;
    })
    .offset(function (data) {
      var j = -1;
      var len = data[0].length;
      var y0 = [];
      while (++j < len) {
        y0[j] = [0, 0];
      }
      return y0;
    })
    .out(function (d, y0, change, y) {
      var posOrNeg = change >= 0 ? 0 : 1;
      y0[posOrNeg] += change;
      d.y0 = y0.slice();
      d.y = y || 0;
    });

  stack(dataDefault);
  var demandaMax = dataDemanda?.values
    ? d3.max(dataDemanda?.values, function (d) {
        return Math.abs(d.value);
      })
    : 0;
  var demandaMaxPosNeg = dataDemanda?.values
    ? d3.max(dataDemanda?.values, function (d) {
        return d.value;
      })
    : 0;
  var demandaarea0 =
    dataDemandaArea0 &&
    d3.max(dataDemandaArea0.values, function (d) {
      return Math.abs(d.value);
    });
  var demandaarea1 =
    dataDemandaArea1 &&
    d3.max(dataDemandaArea1.values, function (d) {
      return d.value;
    });

  var yMax = d3.max(dataDefault, function (type) {
    return d3.max(type.values, function (d) {
      return Math.abs(d.y - d.y0[0]);
    });
  });
  var yMaxPosNeg = d3.max(dataDefault, function (type) {
    return d3.max(type.values, function (d) {
      return Math.abs(d.y - d.y0[0]);
    });
  });
  var yMinComplete = d3.min(dataDefault2, function (type) {
    return d3.min(type.values, function (d) {
      return d.y;
    });
  });
  var yMin = d3.min(dataDefault, function (type) {
    return d3.min(type.values, function (d) {
      return d.y + d.y0[1];
    });
  });

  if (demandaMax > yMax) {
    yMax = demandaMax;
    if (opts?.type === 'multi' && demandaMaxPosNeg > yMaxPosNeg) {
      yMax = demandaMaxPosNeg;

      if (demandaarea0 > demandaMaxPosNeg) {
        yMax = demandaarea0;
      }
    }
    if (opts?.type === 'multi' && demandaMaxPosNeg < yMaxPosNeg) {
      yMax = yMaxPosNeg;
      if (demandaarea0 > yMaxPosNeg) {
        yMax = demandaarea0;
      }
    }
  }
  var rangeData = dataDefault2[0]?.values.map(function (d) {
    return d.x;
  });
  // Definir el rango de fechas en el eje x
  var xAxisDataDef = d3.range(
    rangeData[0],
    rangeData[rangeData.length - 1] + 1,
    1
  );
  if (val23h === 23 && timeType === i18n.t('hour')) {
    xAxisData.splice(2, 1);
  }
  x.domain(rangeData);
  let finalY = 0;
  if (parseInt(yMin) > parseInt(yMinComplete)) {
    finalY = yMinComplete;
  } else {
    finalY = yMin;
  }

  // finalY variable is the mayor of bars and line graphs
  if (opts?.fit) {
    let vals = [];
    let valsMin = [];
    dataDefault.map((el) => {
      el.values.map((val, ind) => {
        if (val.value > 0) {
          vals[ind] = vals[ind] ? vals[ind] + val.value : 0 + val.value;
        } else {
          valsMin[ind] = valsMin[ind]
            ? valsMin[ind] + val.value
            : 0 + val.value;
        }
      });
    });
    let areaVals = [];
    dataDemandaArea0Pos?.values.map((val, ind) => {
      if (val.value > 0) {
        areaVals.push(val.value);
      }
    });
    let areaValsMin = [];
    dataDemandaArea1Neg?.values.map((val, ind) => {
      if (val.value < 0) {
        areaValsMin.push(val.value);
      }
    });

    let valLine = [];
    let valLineMin = [];
    demandaLine.map((el) => {
      el.values.map((val, ind) => {
        if (val.value > 0) {
          valLine.push(val.value);
        } else {
          valLineMin.push(val.value);
        }
      });
    });

    areaVals = areaVals?.filter((el) => el != null);
    areaValsMin = areaValsMin?.filter((el) => el != null);
    vals = vals?.filter((el) => el != null);
    valsMin = valsMin?.filter((el) => el != null);

    valLine = valLine?.filter((el) => el != null);
    valLineMin = valLineMin?.filter((el) => el != null);

    let maxyFinal = vals.length == 0 ? 0 : Math.max(...vals);
    if (maxyFinal <= 10) {
      maxyFinal = 10;
    }
    let maxyFinalArea = areaVals.length == 0 ? 0 : Math.max(...areaVals);
    let minyFinal = valsMin.length == 0 ? 0 : Math.min(...valsMin);
    let minyFinalArea = areaValsMin.length == 0 ? 0 : Math.min(...areaValsMin);
    let minyFinalLineMax = valLine.length == 0 ? 0 : Math.max(...valLine);
    let minyFinalLine = valLineMin.length == 0 ? 0 : Math.min(...valLineMin);

    if (maxyFinalArea > maxyFinal) {
      maxyFinal = maxyFinalArea;
    } else {
      maxyFinal = maxyFinal;
    }

    if (minyFinalLineMax > maxyFinal) {
      maxyFinal = minyFinalLineMax;
    } else {
      maxyFinal = maxyFinal;
    }

    if (minyFinalArea < minyFinal) {
      minyFinal = minyFinalArea;
    } else {
      minyFinal = minyFinal;
    }
    if (minyFinalLine < minyFinal) {
      minyFinal = minyFinalLine;
    } else {
      minyFinal = minyFinal;
    }
    y.domain([minyFinal, maxyFinal]).nice(numTicks);
  } else {
    y.domain([finalY, yMax]).nice(numTicks);
  }

  let nodata = false;
  if (yMax == 0 && finalY == 0) {
    nodata = true;
  }

  // Crear un nuevo objeto para almacenar las posiciones de los nombres de datos
  // Extraer todos los nombres de datos de todos los conjuntos en lasdata
  const nombresDatos = lasdata.flatMap((entry) => {
    if (w < 700 || window.location.pathname.includes('insertado')) {
      if (entry.short_name) {
        return entry.short_name ? [entry.short_name] : [entry.alias];
      } else if (entry.id === 10460) {
        return '% ERNI RRTT RT';
      } else if (entry.id === 10461) {
        return '% ERNI RRTT RTD';
      } else if (entry.id === 10462) {
        return '% ERNI RRTT TOTAL';
      } else {
        return entry.shortName ? [entry.shortName] : [entry.alias];
      }
    } else {
      return entry.name ? [entry.name] : [entry.alias];
    }
  });

  let anchoventana = 350;
  if (w < 700) {
    anchoventana = 150;
  }
  const posicionesNombres = {};
  nombresDatos.forEach((nombre, index) => {
    posicionesNombres[nombre] = index * anchoventana; // Puedes ajustar el valor 50 según tus necesidades
  });

  // Crear una función que devuelva la posición para un nombre dado
  const xPosicion = (nombre) => posicionesNombres[nombre];

  // También puedes seguir usando una escala ordinal si lo prefieres
  const xNombres = d3.scale.ordinal().range(nombresDatos.map(xPosicion));

  // Configurar el eje x con el formato de etiqueta personalizado
  let masyear;
  if (currentYear - inicurrentYear == 2) {
    masyear = true;
  }
  //const xAxis = d3.svg.axis().scale(x).tickFormat((v, i) => formatXAxisLabel((inicurrentMonth + i + 12) % 12, masyear && inicurrentMonth + i < 12 ? currentYear -1 : currentYear - Math.floor((inicurrentMonth + i > 11) ? 0 : (i + 12) / 12))).orient('bottom');
  const xAxis = d3.svg
    .axis()
    .scale(x)
    .tickFormat((v, i) => {
      if (masyear) {
        if (i === 0 && inicurrentMonth + i < 12) {
          return formatXAxisLabel(
            (inicurrentMonth + i + 12) % 12,
            currentYear - 2,
            w
          );
        } else if (i > 0 && i < 13) {
          return formatXAxisLabel(
            (inicurrentMonth + i + 12) % 12,
            currentYear -
              1 -
              Math.floor(inicurrentMonth + i > 11 ? 0 : (i + 12) / 12),
            w
          );
        } else {
          return formatXAxisLabel(
            (inicurrentMonth + i + 12) % 12,
            currentYear -
              Math.floor(inicurrentMonth + i > 11 ? 0 : (i + 12) / 12),
            w
          );
        }
      } else {
        return formatXAxisLabel(
          (inicurrentMonth + i + 12) % 12,
          currentYear -
            Math.floor(inicurrentMonth + i > 11 ? 0 : (i + 12) / 12),
          w
        );
      }
    })
    .orient('bottom');

  var yAxis = d3.svg
    .axis()
    .scale(y)
    .tickFormat(function (d) {
      return d.toFixed(1); // Formato para mostrar 1 decimal
    })
    .orient('right')
    .ticks(numTicks)
    .tickSize(0); // Establecer el tamaño de las marcas del eje en cero

  var yAxisCustom = d3.svg
    .axis()
    .scale(y)
    .ticks(numTicks)
    .tickSize(w)
    .tickFormat('')
    .orient('right');

  // Renderizar el eje x
  svgBars
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0, 260)')
    .call(xAxis);

  let posicionmesesx = -3;
  let posicionmesesy = 278;
  if (w < 450) {
    posicionmesesx = -8;
  }
  svgBars
    .append('text')
    .attr('class', 'axisLabel x')
    .attr(
      'transform',
      'translate(' + posicionmesesx + ',' + posicionmesesy + ')'
    )
    .style('text-anchor', 'middle')
    .style('text-transform', 'capitalize')
    .text(
      opts?.bottom?.hideHour
        ? ''
        : opts?.bottom?.label
        ? i18n.t(opts?.bottom?.label)
        : ''
    );

  svgBars
    .append('g')
    .attr('class', 'yaxis')
    .attr('transform', 'translate(-23, -10)')
    .call(yAxis)
    .append('text')
    .attr('y', 3)
    .attr('dy', '.71em')
    .style('text-anchor', 'end');

  svgBars
    .append('text')
    .attr('class', 'axisLabel')
    .attr('transform', 'translate(-10 , -21)')
    .style('text-anchor', 'end')
    .text(opts?.left?.label === 'empty' ? '' : opts?.left?.label || 'MWh');

  let posicionname = 40;
  let anchobarra = 18;
  if (w < 500) {
    posicionname = 0;
    anchobarra = 10;
  }

  // Agregar rectángulos de colores debajo del eje x
  svgBars
    .selectAll('.data-name-rect')
    .data(nombresDatos)
    .enter()
    .append('rect')
    .attr('class', 'data-name-rect')
    .attr('x', function (d, i) {
      if (w < 500) {
        return 0;
      } else {
        return xNombres(d) + posicionname; // Ajusta la posición horizontal del rectángulo según tu diseño para escritorio
      }
    })
    .attr('y', function (d, i) {
      if (w < 500) {
        return 310 + i * 30;
      } else {
        return 310;
      }
    }) // Ajusta la posición vertical del rectángulo según tu diseño
    .attr('width', anchobarra) // Ajusta el ancho del rectángulo según tu diseño
    .attr('height', 4) // Ajusta la altura del rectángulo según tu diseño
    .style('fill', (d, i) => getColour(i)); // Asigna colores a los rectángulos

  // Agregar nombres de datos al lado de cada rectángulo
  svgBars
    .selectAll('.data-name-label')
    .data(nombresDatos)
    .enter()
    .append('text')
    .attr('class', 'data-name-label')
    .attr('x', function (d, i) {
      if (w < 500) {
        return 15;
      } else {
        return xNombres(d) + 63; // Ajusta la posición horizontal del texto según tu diseño para la vista escritorio
      }
    })
    .attr('y', function (d, i) {
      if (w < 500) {
        return 315 + i * 30;
      } else {
        return 315;
      }
    }) // Ajusta la posición vertical del texto según tu diseño
    .style('text-anchor', 'start')
    .text(function (d) {
      // Divide el texto en palabras
      const words = d.split(' ');
      let line = '';
      const maxCharsPerLine = 50;
      const lines = [];

      // Recorre las palabras y agrégalas a la línea actual
      words.forEach((word) => {
        if ((line + word).length > maxCharsPerLine) {
          lines.push(line);
          line = word;
        } else {
          line += (line ? ' ' : '') + word;
        }
      });
      lines.push(line);

      return lines.join('\n'); // Une las líneas con saltos de línea
    })
    .each(function () {
      // Ajustar el texto SVG para admitir saltos de línea
      const text = d3.select(this);
      const lines = text.text().split('\n');
      text.text(null);
      lines.forEach((line, i) => {
        text
          .append('tspan')
          .attr('x', text.attr('x'))
          .attr('dy', i ? '1.2em' : 0)
          .text(line);
      });
    });

  svgBars
    .append('text')
    .attr('class', 'axisLeftCenterLabel')
    .text(opts?.leftCenter?.label || '');

  if (demandaMax >= 0) {
    var areaFunc = d3.svg
      .area()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d, a) {
        if (val23h == 23) {
          if (a > 1) {
            a = a + 1;
          }
        }
        if (val23h == 25) {
          return a > 23 ? x(d.x) + x.rangeBand() : x(d.x);
        }
        return a == 23 ? x(d.x) + x.rangeBand() : x(d.x);
      })
      .y1(function (d) {
        return y(d.y);
      })
      .y0(function (d) {
        return y(0);
      });

    var areaFuncNeg = d3.svg
      .area()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d, a) {
        if (val23h == 23) {
          if (a > 1) {
            a = a + 1;
          }
        }
        if (val23h == 25) {
          return a > 23 ? x(d.x) + x.rangeBand() : x(d.x);
        }
        return a == 23 ? x(d.x) + x.rangeBand() : x(d.x);
      })
      .y0(function (d) {
        return y(0);
      })
      .y1(function (d) {
        return y(d.y);
      });
  }
  var bar = svgBars
    .selectAll('g.bars')
    .data(dataDefault)
    .enter()
    .append('svg:g')
    .attr('class', 'bars')
    .attr('transform', function () {
      return 'translate(0,0)';
    })
    .style('fill', function (d) {
      if (d.id == 10460) {
        return '#046BBC';
      } else {
        return '#F68709';
      }
    });
  let valsForArrOfBars = [];
  let barVals = d3.selectAll('.axisDef .tick');
  barVals[0].map((el) => {
    let pos = el?.getAttribute('transform').split('(')[1];
    pos = pos.split(',')[0];
    valsForArrOfBars.push(parseInt(pos));
  });

  var paddingPos = [];
  var padding = [];

  bar
    .selectAll('rect')
    .data(function (d) {
      return d.values;
    })
    .enter()
    .append('svg:rect')
    .attr('x', function (d) {
      return x(d.x);
    })
    .attr('y', function (d, i, e) {
      if (d.value > 0) {
        if (d.y0[1] === 0 && e === 0) {
          paddingPos[d.x + 1] = Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
        }
        if (paddingPos[d.x + 1] && e !== 0) {
          return y(d.y + d.y0[0]) + paddingPos[d.x + 1];
        } else {
          return y(d.y + d.y0[0]);
        }
      } else {
        if (d.y0[0] === 0 && e === 0) {
          padding[d.x] = Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
        }
        if (padding[d.x]) {
          return y(d.y0[1]) - padding[d.x];
        } else {
          return y(d.y0[1]);
        }
      }
    })
    .attr('height', function (d) {
      return Math.abs(y(d.y0[0]) - y(d.y0[0] + d.value));
    })
    .attr('width', x.rangeBand())
    .attr('data-value', function (d) {
      return d.value;
    })
    .style('fill', function (d) {
      return getColour(nombresDatos.indexOf(d.name || d.alias));
    });

  var gy = svgBars.append('g').attr('class', 'y axis').call(yAxisCustom);

  gy.selectAll('g')
    .attr('class', function (d) {
      var axisValue = Math.abs(d);
      return 'minor axis-line-' + axisValue;
    })
    .filter(function (d) {
      return d;
    });

  gy.selectAll('text').attr('x', 4).attr('dy', -4);
  if (demandaMax > 0) {
    let lineInterpolation = opts?.lineInterpolation
      ? opts.lineInterpolation
      : 'basis';
    var line = d3.svg
      .line()
      .defined(function (d) {
        return d.value !== null;
      })
      .x(function (d) {
        return x(d.x);
      })
      .y(function (d) {
        return y(d.y);
      })
      .interpolate(lineInterpolation);

    if (dataDemanda?.imexport) {
      dataimport &&
        svgBars
          .append('g')
          .append('path')
          .attr('class', 'bar__line_import')
          .attr('d', line(dataimport.values))
          .attr('fill', 'none');

      dataexport &&
        svgBars
          .append('g')
          .append('path')
          .attr('class', 'bar__line_export')
          .attr('d', line(dataexport.values))
          .attr('fill', 'none');
    } else {
      svgBars
        .append('g')
        .append('path')
        .attr('class', 'bar__line')
        .attr('transform', function () {
          return 'translate(20,0)';
        })
        .attr('d', line(dataDemanda?.values))
        .attr('stroke', '#9C9C9C')
        .attr('fill', 'none');
    }
  }

  if (opts?.multibar) {
    // TODO: CHECK this for trail
    let bg = svgBars
      .append('g')
      .style('pointer-events', 'all')
      .attr('class', 'background');
    bg.append('rect')
      .style('cursor', 'pointer')
      .attr('class', 'bg')
      .attr('x', '0')
      .attr('fill', 'none')
      .attr('width', w)
      .attr('height', h);

    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let arr = opts.date.split(' / ');
    arr = opts.date.split('-');

    let currentPositionSelected = 23;
    if (arr[0] == day && arr[1] == month && arr[2] == year) {
      let date = new Date();
      let hours = date.getHours();
      currentPositionSelected = hours;
    }

    if (nodata) {
      currentPositionSelected = 0;
    }
  }
};

let spinnerNone = (id) => {
  document.getElementById(id).style.display = 'none';
  document.querySelector('#tabwithcontent').classList.remove('not-show');
  document
    .querySelector('.esios-balance__container')
    .classList.remove('is-charging');
};
let spinnerTrue = (id) => {
  document.getElementById(id).style.display = 'flex';
  document
    .querySelector('.esios-balance__container')
    .classList.add('is-charging');
  document.querySelector('#tabwithcontent').classList.add('not-show');
};
let programs = {
  P48: {
    name: 'P48',
    indicators: [
      10063, 10010, 84, 85, 10013, 2131, 74, 73, 79, 10008, 10009, 10011, 10012,
      95, 96, 10015, 10014, 10016, 10017, 10026, 10025, 10027,
    ],
  },
  PBF: {
    name: 'PBF',
    indicators: [
      10064, 10073, 14, 15, 10074, 4, 3, 9, 10167, 10077, 10086, 10095, 25, 26,
      10104, 10113, 10122, 10131, 10186, 10196, 10141,
    ],
  },
  PHF1: {
    name: 'PHF1',
    indicators: [
      10066, 10159, 119, 120, 10177, 109, 108, 114, 10169, 10079, 10088, 10097,
      130, 131, 10106, 10115, 10124, 10133, 10188, 10198, 10143,
    ],
  },
  PHF2: {
    name: 'PHF2',
    indicators: [
      10067, 10160, 154, 155, 10178, 144, 143, 149, 10170, 10080, 10089, 10098,
      165, 166, 10107, 10116, 10125, 10134, 10189, 10199, 10144,
    ],
  },
  PHF3: {
    name: 'PHF3',
    indicators: [
      10068, 10161, 189, 190, 10179, 179, 178, 184, 10171, 10081, 10090, 10099,
      200, 201, 10108, 10117, 10126, 10136, 10190, 10200, 10145,
    ],
  },
  PHF4: {
    name: 'PHF4',
    indicators: [
      10069, 10162, 224, 225, 10180, 214, 213, 219, 10172, 10082, 10091, 10100,
      235, 236, 10109, 10118, 10127, 10137, 10191, 10201, 10146,
    ],
  },
  PHF5: {
    name: 'PHF5',
    indicators: [
      10070, 10163, 259, 260, 10181, 249, 248, 254, 10173, 10083, 10092, 10101,
      270, 271, 10110, 10119, 10128, 10138, 10192, 10202, 10147,
    ],
  },
  PHF6: {
    name: 'PHF6',
    indicators: [
      10071, 10164, 294, 295, 10182, 284, 283, 289, 10174, 10084, 10093, 10102,
      305, 306, 10111, 10120, 10129, 10139, 10193, 10203, 10148,
    ],
  },
  PHF7: {
    name: 'PHF7',
    indicators: [
      10072, 10165, 329, 330, 10183, 319, 318, 324, 10175, 10085, 10094, 10103,
      340, 341, 10112, 10121, 10130, 10140, 10194, 10204, 10149,
    ],
  },
  PHFC: {
    name: 'PHFC',
    indicators: [
      10271, 10281, 1413, 1414, 10283, 1403, 1402, 1408, 10282, 10272, 10273,
      10274, 1424, 1425, 10275, 10276, 10277, 10278, 10284, 10285, 10279,
    ],
  },
  PVP: {
    name: 'PVP',
    indicators: [
      10065, 10158, 49, 50, 10176, 39, 38, 44, 10168, 10078, 10087, 10096, 60,
      61, 10105, 10114, 10123, 10132, 10187, 10197, 10142,
    ],
  },
};
export let categories = [
  {
    name: 'Hidráulica',
    color: '#0090d1',
    legendtype: 'circle',
    order: 11,
    alias: false,
    indicators: [
      10063, 10064, 10066, 10067, 10068, 10069, 10070, 10071, 10072, 10271,
      10065,
    ],
  },
  {
    name: 'Eólica',
    color: '#6fb124',
    legendtype: 'circle',
    order: 9,
    alias: false,
    indicators: [
      10010, 10073, 10159, 10160, 10161, 10162, 10163, 10164, 10165, 10281,
      10158,
    ],
  },
  {
    name: 'Solar fotovoltaica',
    color: '#e48500',
    legendtype: 'circle',
    order: 8,
    alias: false,
    indicators: [84, 14, 119, 154, 189, 224, 259, 294, 329, 1413, 49],
  },
  {
    name: 'Solar térmica',
    color: '#ff0000',
    legendtype: 'circle',
    order: 7,
    alias: false,
    indicators: [85, 15, 120, 155, 190, 225, 260, 295, 330, 1414, 50],
  },
  {
    name: 'Otras renovables',
    color: '#B4D490',
    legendtype: 'circle',
    order: 5,
    alias: true,
    indicators: [
      10013, 10074, 10177, 10178, 10179, 10180, 10181, 10182, 10183, 10283,
      10176,
    ],
  },
  {
    name: 'Hibridación',
    color: '#54b8be',
    legendtype: 'circle',
    order: 6,
    alias: true,
    indicators: [
      2131, 2132, 2133, 2134, 2135, 2136, 2137, 2138, 2139, 2140, 2141,
    ],
  },
  {
    name: 'Nuclear',
    color: '#464394',
    legendtype: 'circle',
    order: 16,
    alias: false,
    indicators: [74, 4, 109, 144, 179, 214, 249, 284, 319, 1403, 39],
  },
  {
    name: 'Turbinación bombeo',
    color: '#86c6e3',
    legendtype: 'circle',
    order: 10,
    alias: false,
    indicators: [73, 3, 108, 143, 178, 213, 248, 283, 318, 1402, 38],
  },
  {
    name: 'Ciclo combinado',
    color: '#ffcc66',
    legendtype: 'circle',
    order: 13,
    alias: false,
    indicators: [79, 9, 114, 149, 184, 219, 254, 289, 324, 1408, 44],
  },
  {
    name: 'Carbón',
    color: '#ad5c34',
    legendtype: 'circle',
    order: 14,
    alias: false,
    indicators: [
      10008, 10167, 10169, 10170, 10171, 10172, 10173, 10174, 10175, 10282,
      10168,
    ],
  },
  {
    name: 'Fuel-gas',
    color: '#ba0f16',
    legendtype: 'circle',
    order: 14,
    alias: false,
    indicators: [
      10009, 10077, 10079, 10080, 10081, 10082, 10083, 10084, 10085, 10272,
      10078,
    ],
  },
  {
    name: 'Cogeneración',
    color: '#00a150',
    legendtype: 'circle',
    order: 12,
    alias: false,
    indicators: [
      10011, 10086, 10088, 10089, 10090, 10091, 10092, 10093, 10094, 10273,
      10087,
    ],
  },
  {
    name: 'Residuos no renovables',
    color: '#717171',
    legendtype: 'circle',
    order: 4,
    alias: false,
    indicators: [
      10012, 10095, 10097, 10098, 10099, 10100, 10101, 10102, 10103, 10274,
      10096,
    ],
  },
  {
    name: 'Consumo bombeo',
    color: '#23A2D8',
    legendtype: 'circle',
    order: 3,
    alias: false,
    indicators: [95, 25, 130, 165, 200, 235, 270, 305, 340, 1424, 60],
  },
  {
    name: 'Enlace Baleares',
    color: '#FF8282',
    legendtype: 'circle',
    order: 2,
    alias: false,
    indicators: [96, 26, 131, 166, 201, 236, 271, 306, 341, 1425, 61],
  },
  {
    name: 'Saldo Francia',
    color: '#3CB375',
    legendtype: 'circle',
    order: 20,
    alias: false,
    indicators: [
      10015, 10104, 10106, 10107, 10108, 10109, 10110, 10111, 10112, 10275,
      10105,
    ],
  },
  {
    name: 'Saldo Portugal',
    color: '#AA7BCB',
    legendtype: 'circle',
    order: 19,
    alias: false,
    indicators: [
      10014, 10113, 10115, 10116, 10117, 10118, 10119, 10120, 10121, 10276,
      10114,
    ],
  },
  {
    name: 'Saldo Marruecos',
    color: '#A0546D',
    legendtype: 'circle',
    order: 18,
    alias: false,
    indicators: [
      10016, 10122, 10124, 10125, 10126, 10127, 10128, 10129, 10130, 10277,
      10123,
    ],
  },
  {
    name: 'Saldo Andorra',
    color: '#A2D468',
    legendtype: 'circle',
    order: 17,
    alias: false,
    indicators: [
      10017, 10131, 10133, 10134, 10136, 10137, 10138, 10139, 10140, 10278,
      10132,
    ],
  },
  {
    name: 'Ajuste de programas',
    color: '#8C0000',
    legendtype: 'circle',
    order: 1,
    alias: true,
    indicators: [
      10025, 10196, 10198, 10199, 10200, 10201, 10202, 10203, 10204, 10285,
      10197,
    ],
  },
  {
    name: 'Demanda Peninsular',
    color: '#00D7D9',
    legendtype: 'line',
    order: 30,
    alias: false,
    indicators: [
      10027, 10141, 10143, 10144, 10145, 10146, 10147, 10148, 10149, 10279,
      10142,
    ],
  },
];

export const checkIndicatorInCategory = (indicators, category) => {
  var validIds = _.findWhere(categories, { name: category });
  var ids = _.intersection(_.pluck(indicators, 'id'), validIds.indicators);

  if (ids.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const getByDay = (program, mainData) => {
  var data = mainData;

  var result = _.filter(data.indicators, function (indicator) {
    return _.contains(programs[program].indicators, indicator.id);
  });
  var categoriesGrouped = _.groupBy(data.indicators, 'alias');
  let categoriesFiltered = _.map(categories, function (category) {
    var indicators = _.filter(categoriesGrouped, function (cat) {
      var ids = _.intersection(_.pluck(cat, 'id'), category.indicators);
      return ids.length > 0;
    });
    var values = _.intersection(
      _.pluck(indicators[0], 'id'),
      programs[program].indicators
    );

    if (values.length > 0) {
      return {
        id: result.filter((el) => el.alias === indicators[0][0].alias)[0].id,
        name: indicators[0][0].alias,
        shortName: getCategoryName(indicators[0]),
        color: category.color,
        legendtype: category.legendtype,
        demanda: checkIndicatorInCategory(indicators[0], 'Demanda Peninsular'),
        order: category.order,
      };
    }
  });

  var categoriesData = _.filter(categoriesFiltered, function (category) {
    if (category) {
      category.values = [];
      return category;
    }
  });

  return categoriesData;
};

export const getByMonth = (program, mainData) => {
  var data = mainData;

  var result = _.filter(data.indicators, function (indicator) {
    return _.contains(programs[program].indicators, indicator.id);
  });
  var categoriesGrouped = _.groupBy(data.indicators, 'alias');
  let categoriesFiltered = _.map(categories, function (category) {
    var indicators = _.filter(categoriesGrouped, function (cat) {
      var ids = _.intersection(_.pluck(cat, 'id'), category.indicators);
      return ids.length > 0;
    });
    var values = _.intersection(
      _.pluck(indicators[0], 'id'),
      programs[program].indicators
    );

    if (values.length > 0) {
      return {
        id: result.filter((el) => el.alias === indicators[0][0].alias)[0].id,
        name: indicators[0][0].alias,
        shortName: getCategoryName(indicators[0]),
        color: category.color,
        legendtype: category.legendtype,
        demanda: checkIndicatorInCategory(indicators[0], 'Demanda Peninsular'),
        order: category.order,
      };
    }
  });

  var categoriesData = _.filter(categoriesFiltered, function (category) {
    if (category) {
      category.values = [];
      return category;
    }
  });

  return categoriesData;
};

export const getCategoryName = (categories) => {
  var category = _.filter(categories, function (cat) {
    var ids = _.intersection(_.pluck(categories, 'id'), cat.indicators);
    return ids.length > 0;
  });

  if (category[0]) {
    if (category[0].alias) {
      return categories[0].alias;
    } else {
      return categories[0].short_name;
    }
  } else {
    return categories[0].short_name;
  }
};

const calculateOrder = (date, dateFormat) => {
  let finalNumber;
  if (dateFormat === 'hour') {
    finalNumber = parseInt(date.split('T')[1]);
  } else if (dateFormat === 'day') {
    finalNumber = parseInt(date.split('-')[0]) - 1;
  } else {
    finalNumber = parseInt(date.split('-')[1]) - 1;
  }
  return finalNumber;
};

const fillSimpleVals = (rangeNumber) => {
  let arr = [];
  for (let i = 0; i < rangeNumber; i++) {
    arr.push({
      x: i,
      order: i,
      y: null,
      value: null,
      color: '#333333',
      name: '',
    });
  }
  return arr;
};
export const calculateHoursPerDay = (day) => {
  let date = moment(day, 'DD-MM-YYYY').format('DD-MM-YYYY');
  let secondDate = moment(day, 'DD-MM-YYYY').add(1, 'day').format('DD-MM-YYYY');
  // check day hours
  var a = moment(date, 'DD-MM-YYYY').tz('Europe/Madrid').startOf('day');
  var b = moment(secondDate, 'DD-MM-YYYY').tz('Europe/Madrid').startOf('day');
  var diff = b.diff(a, 'hours');
  return diff;
};

export const validateDateAndAgg = (firstArr, secondArr) => {
  let { date, agg } = getUrlParams();
  let dateVal = calculateHoursPerDay(date);
  if (dateVal === 23 && agg === 'hour') {
    firstArr.splice(2, 1);
    secondArr.splice(2, 1);
  }

  return {
    newFirstArr: firstArr,
    newDayFirstTabValue: secondArr,
  };
};
