import moment from 'moment';
import { getHigherArr } from '../../../pages/analysis/chart/analysis-comp-temp-chart';
import { urlDateToJSDate } from '../../../utils/dateFormat';
import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';
import { Utils } from '../../../utils/utils';

export const CHART_OPTS = (
  values,
  { onStep, topEnabled, decimalNumbers } = {},
  analysis
) => {
  const { currentLang } = getState();
  const {
    start_date,
    end_date,
    compare_start_date,
    compare_end_date,
    tabSelected,
    groupBy,
    indicator,
  } = analysis;
  const startDate = new Date(urlDateToJSDate(start_date));
  const endDate = new Date(urlDateToJSDate(end_date));
  const compareStartDate = new Date(urlDateToJSDate(compare_start_date));
  // const compareEndDate = new Date(urlDateToJSDate(compare_end_date));

  const getCompareDate = (start_date, end_date, compare_date, type) => {
    const dateTemplate = 'DD-MM-YYYYTHH:mm';
    let fixedDiff = moment.duration(
      moment(moment(start_date, dateTemplate).format()).diff(
        moment(end_date, dateTemplate).format()
      )
    );
    fixedDiff = fixedDiff < 0 ? fixedDiff.asHours() * -1 : fixedDiff.asHours();
    let compareDate;
    if (type === 'start_date') {
      compareDate = moment(compare_date, dateTemplate)
        .subtract(fixedDiff, 'hours')
        .format(dateTemplate);
    } else {
      compareDate = moment(compare_date, dateTemplate)
        .add(fixedDiff, 'hours')
        .format(dateTemplate);
    }
    return compareDate;
  };

  let compareEndDate = getCompareDate(start_date, end_date, compare_start_date);
  compareEndDate = new Date(urlDateToJSDate(compareEndDate));
  const groupby = getUrlParams().groupby; // Obtener el parámetro de agrupamiento de la URL
  const diffInDays = Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24)); // Calcular la diferencia en días
  return {
    forceSpainDate: true,
    locale: currentLang,
    margin: '10 55 24 55',
    noFakeValue: true,
    trail: {
      enabled: true,
      initXValue,
      parseStep,
    },
    xaxis: {
      fit: true,
      scale: 'time',
      bottom: {
        domain: [startDate, endDate],
        ...((groupby === 'minutes15' ||
          groupby === 'hour' ||
          groupby === 'day' ||
          groupby === 'month') &&
          diffInDays > 14 && {
            tickFormat: (date) => {
              const month = date.toLocaleString('default', { month: 'short' }); // Obtener el nombre del mes abreviado
              const day = date.getDate(); // Obtener el día
              return `${day} ${month.charAt(0).toUpperCase() + month.slice(1)}`;
            },
          }),
      },
      top: {
        domain: [compareStartDate, compareEndDate],
        enabled: topEnabled,
      },
    },
    yaxis: {
      fit: true,
      left: {
        label: 's',
        tickFormat: function (y) {
          return Utils.toNumber(y, decimalNumbers, 'comma');
        },
      },
      right: {
        tickFormat: function (y) {
          return Utils.toNumber(y, decimalNumbers, 'comma');
        },
      },
      textMarginTop: -10,
    },
  };
  function initXValue() {
    // returns the last value and fix the trail on it
    const lastsValues = values.map((ind) => {
      if (ind.values) {
        // Hasta encontrar un valor final que no sea null
        for (let i = ind.values.length - 1; i >= 0; i--) {
          if (ind.values[i].y !== null) {
            return ind.values[i]; // Devolvemos el primer valor encontrado que no sea null
          }
        }
      } else if (ind.data) {
        return ind.values
          ? ind.values[ind.values.length - 1]
          : ind.data[0].values[ind.data[0].values.length - 1];
      } else {
        return null; // Si no se encuentra ningún valor no nulo
      }
    });
    const last = lastsValues.reduce((p, n) => {
      if (!n) {
        return p;
      }
      if (!p) {
        return n;
      }

      return p.x > n.x ? n : p;
    });

    return last.x;
  }
  function parseStep(date) {
    let step = new Date(date);
    const minutes = step.getMinutes();

    // Default minutes
    let minute = 10;
    let timeIdVal = indicator.tiempo[0].id;
    if (timeIdVal === 154) {
      timeIdVal = 225;
    }
    minute = Utils.getTimeValue(timeIdVal) || 10;
    const { higherArr } =
      tabSelected.label === 'temporal' && getHigherArr([...values], 'values');
    // Common or breakdown chart
    const list =
      (tabSelected.label === 'temporal'
        ? higherArr.values
        : values[0].values) || values[0].data[0].values;
    // Has no sense if the list has only one element
    if (1 < list.length) {
      const first = list[0];
      const next = list[1];
      const firstMin = first.x.getMinutes();
      const nextMin = next.x.getMinutes();
      if (Math.abs(nextMin - firstMin) === 15) {
        minute = 15;
      }
    }
    let { groupby } = getUrlParams();
    const vals = values[0].values ? values : values[0].data;

    if (groupby == 'minutes5') {
      const roundMin = Math.round(minutes / minute) * minute;
      step.setMinutes(roundMin);
      step.setSeconds(0);
    } else if (groupby == 'day') {
      step = new Date(moment(date).startOf('day'));
    } else if (groupby == 'month') {
      step = new Date(moment(date).startOf('month'));
    } else if (groupby == 'year') {
      step = new Date(moment(date).startOf('year'));
    } else if (groupby == 'hour') {
      step = new Date(moment(date).startOf('hour'));
    } else {
      step = setCloserDate(step);
    }
    let data = vals.map((ind) => ({
      ...ind,
      value: ind.values.find((i) => i?.x?.getTime() === step?.getTime()),
    }));
    if (values[0].data && values[1] && values[1].values) {
      const addData = values[1].values.find(
        (i) => i?.x?.getTime() === step?.getTime()
      );
      data = [{ ...values[1], value: addData }, ...data];
    }

    onStep && onStep(data, step);
    return new Date(date);
  }
  function setCloserDate(date) {
    const hasBreakDown = !!values[0].data;
    let list;
    if (hasBreakDown) {
      list = values[0].data[0].values;
    } else {
      let maxDate = 0;
      // we have to search the largest date to avoid failing trail event
      for (let i = 0; i < values.length; i++) {
        if (values[maxDate].values.length < values[i].values.length) {
          maxDate = i;
        }
      }
      list = values[maxDate].values;
    }
    const milliseconds = date.getTime();
    const newDate = list.reduce((prev, current) => {
      const prevDiff = Math.abs(prev?.x?.getTime() - milliseconds);
      const currentDiff = Math.abs(current?.x?.getTime() - milliseconds);
      return prevDiff < currentDiff ? prev : current;
    });
    return newDate.x;
  }
};
